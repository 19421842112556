/**
 * Handles all of the security checks in the application.
 */
export default class Security {
    static memo = {};
    static context;

    /**
     * @param {object} context The context.
     */
    static setContext(context) {
        this.context = context;
    }

    /**
     * @param {string} permission The permission to check
     * @returns {boolean} Whether or not the current user has that permission.
     */
    static hasPermission(permission) {
        // Assume no permission if this is run with no context.
        if (!this.context) {
            return false;
        }

        const user = this.context.currentUser;
        const key = `${permission}.${user.id}`;

        if (this.memo.hasOwnProperty(key) === false) {
            switch (permission) {
                case "queue.viewHistoryChart":
                    this.memo[key] = (
                        this.hasRole(user, ["ADMIN", "EXECUTIVE_MANAGEMENT"]) ||
                        this.isExecutive(user) ||
                        this.isDirector(user) ||
                        this.isManager(user)
                    );
                    break;
                case "queue.viewAll":
                    /**
                     * If you are an admin, an exec, a director, or are not on a
                     * team and are note a manager, then you can view all
                     * queues. Marketing also gets to view everything.
                     */
                    this.memo[key] = (
                        this.hasRole(user, ["ADMIN", "EXECUTIVE_MANAGEMENT", "MARKETING", "MARKETING_MANAGER"]) ||
                        this.isExecutive(user) ||
                        this.isDirector(user)
                    );
                    break;
                case "queue.useRegionPrefilter":
                    /**
                     * If you are on a team that uses the region prefilter or
                     * you can view all regions.
                     */
                    this.memo[key] = (
                        this.isOnADepartmentThatUsesPrefilter(user, "region") ||
                        this.hasPermission("user.viewAllRegions")
                    );
                    break;
                case "queue.useLocationPrefilter":
                    /**
                     * If you are on a team that uses the location prefilter or
                     * you can view all locations.
                     */
                    this.memo[key] = (
                        this.isOnADepartmentThatUsesPrefilter(user, "location") ||
                        this.hasPermission("user.viewAllLocations")
                    );
                    break;
                case "queue.useUserPrefilter":
                    /**
                     * If you are on a team that uses the user prefilter or
                     * you can view all users.
                     */
                    this.memo[key] = (
                        this.isOnADepartmentThatUsesPrefilter(user, "user") ||
                        this.hasPermission("user.viewAllUsers")
                    );
                    break;
                case "queue.useMarketingCampaignPrefilter":
                    /**
                     * If you are on a team that uses the marketing campaign
                     * prefilter or are an executive or admin.
                     */
                    this.memo[key] = (
                        this.isOnADepartmentThatUsesPrefilter(user, "marketingCampaign") ||
                        this.hasRole(user, ["ADMIN", "EXECUTIVE_MANAGEMENT"])
                    );
                    break;
                case "user.viewAllRegions":
                    /**
                     * Whether or not the user can view all regions. Typically
                     * used for filtering a dropdown or search.
                     */
                case "user.viewAllLocations":
                    /**
                     * Whether or not the user can view all locations. Typically
                     * used for filtering a dropdown or search.
                     */
                case "user.viewAllUsers":
                    /**
                     * Whether or not the user can view all users. Typically
                     * used for filtering a dropdown or search.
                     */
                    this.memo[key] = (
                        this.hasRole(user, ["ADMIN", "EXECUTIVE_MANAGEMENT", "MARKETING", "MARKETING_MANAGER"]) ||
                        this.isExecutive(user)
                    );
                    break;
                case "user.viewAllUsersForMyLocations":
                    /**
                     * Whether or not the user can view all users for their
                     * assigned locations. Typically used for filtering a
                     * dropdown or search.
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "EXECUTIVE_MANAGEMENT",
                                "OFFICE_MANAGER",
                                "AREA_MANAGER",
                                "SALES_MANAGER",
                            ]
                        ) ||
                        this.isExecutive(user)
                    );
                    break;
                case "module.reverseQualityLogs":
                    /**
                     * Whether or not the dropdown option in the app bar
                     * renders Reverse Quality Logs
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                              "ADMIN",
                              "EXECUTIVE_MANAGEMENT",
                              "SALES_MANAGER",
                              "MARKETING_MANAGER",
                              "AREA_MANAGER",
                              "SALES_LIAISON_MANAGER",
                              "SALES_LIAISON"
                            ]
                        ) ||
                        this.isManager(user)
                    );
                    break;
                case "module.reverseQualityReasons":
                    /**
                     * Whether or not the dropdown option in the app bar
                     * renders Reverse Quality Reasons
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN"
                            ]
                        )
                    );
                    break;
                case "module.metric":
                    /**
                     * Whether or not the dropdown option in the app bar
                     * renders Metric Report
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "EXECUTIVE_MANAGEMENT",
                            ]
                        )
                    );
                    break;
                case "module.ARDashboard":
                    /**
                     * Whether or not the dropdown option in the app bar
                     * renders AR Dashboard
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "EXECUTIVE_MANAGEMENT"
                            ]
                        ) 
                    );
                    break;
                case "module.salesPcrLinks":
                    /**
                     * Whether or not the dropdown option in the app bar
                     * renders Sales PCR Link
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "EXECUTIVE_MANAGEMENT",
                                "SALES_MANAGER",
                                "MARKETING",
                                "MARKETING_MANAGER",
                                "USER_ADMIN"
                            ]
                        )
                    );
                    break;
                case "module.presidentsClub":
                    /**
                     * Whether or not the dropdown option in the app bar
                     * renders Presidents Club
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "EXECUTIVE_MANAGEMENT",
                            ]
                        )
                    );
                    break;
                case "module.salesBonus":
                    /**
                     * Whether or not the dropdown option in the app bar
                     * renders Sales Bonus
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "FINANCE",
                            ]
                        )
                    );
                    break;
                case "module.liaisonMeetings":
                    /**
                     * Whether or not the dropdown option in the app bar
                     * renders Sales Liaison Meetings
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "SALES_LIAISON",
                                "SALES_LIAISON_MANAGER",
                                "EXECUTIVE_MANAGEMENT"
                            ]
                        )
                    );
                    break;
                case "module.purchasingQueues":
                case "module.inventoryRequest":
                case "module.poDeliveryUpdate":
                case "module.inventoryProducts":
                case "module.vendors":
                case "module.shippingDetailsReport":
                case "module.manageServiceParts":
                    /**
                     * Whether or not the dropdown options in the app bar
                     * render menu options under Purchasing
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "EXECUTIVE_MANAGEMENT",
                                "PURCHASING",
                                "AREA_MANAGER",
                                "WAREHOUSE_TECHNICIAN",
                                "OFFICE_MANAGER",
                                "SALES_LIAISON"
                            ]
                        )
                    );
                    break;
                case "module.productCategories":
                    /**
                     * Whether or not the dropdown options in the app bar
                     * render menu options under Purchasing
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "EXECUTIVE_MANAGEMENT",
                                "PURCHASING",
                            ]
                        )
                    );
                    break;
                case "module.servicePartCategories":
                    /**
                     * Whether or not the dropdown options in the app bar
                     * render menu options under Purchasing
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "EXECUTIVE_MANAGEMENT",
                                "PURCHASING",
                            ]
                        )
                    );
                    break;
                case "module.marketingCampaigns":
                case "module.marketingCampaignCategories":
                    /**
                     * Whether or not the dropdown options in the app bar
                     * render menu options under Marketing
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "EXECUTIVE_MANAGEMENT",
                                "MARKETING_MANAGER",
                                "MARKETING"
                            ]
                        )
                    );
                    break;
                case "module.marketingPreferredContactMethods":
                    /**
                     * Whether or not the dropdown options in the app bar
                     * render menu options under Marketing
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                            ]
                        )
                    );
                    break;
                case "module.adminUsers":
                case "module.adminTeams":
                        this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "USER_ADMIN",
                            ]
                        )
                    );
                    break;
                case "module.adminDepartments":
                case "module.adminPayorSources":
                case "module.adminInsurance":
                case "module.adminInsuranceGroups":
                case "module.adminInsuranceDetails":
                case "module.adminAccountTypes":
                case "module.adminNotificationTypes":
                case "module.adminContactTypes":
                case "module.adminCreditCardTypes":
                case "module.adminExpenseTypes":
                case "module.adminDocumentTypes":
                case "module.adminStatusReasons":
                case "module.adminLocations":
                case "module.adminRegions":
                case "module.adminManualDocumentBuilder":
                case "module.adminDeliveryFormAcknowledgements":
                case "module.adminOrderActivityTypes":
                case "module.adminQueues":
                case "module.adminQueueTypes":
                case "module.adminQueueRoutes":
                case "module.adminCommissionTiers":
                case "module.adminPlaceOfServices":
                case "module.adminBrightreeInvoiceStatuses":
                case "module.adminInvoiceFollowupStatuses":
                case "module.adminProductSizes":
                case "module.adminBaseUnitTypes":
                case "module.adminBaseUnits":
                case "module.adminBrightreeProducts":
                case "module.adminIssueCategories":
                case "module.adminIssueReasons":
                case "module.adminPatientTrainingVideos":
                case "module.adminProductMatrixEntries":
                case "module.adminPatientAppApprovals":
                case "module.adminDistributionTypes":
                case "module.adminDistributionReasons":
                case "module.adminHcpcsCodes":
                case "module.adminUserProductionTypes":
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN"
                            ]
                        )
                    );
                    break;
                case "module.operationsPatientCollections":
                    /**
                     * Whether or not the user can access the Patient
                     * Collections page to view and modify the list of patients
                     * in collections.
                     */
                    this.memo[key] = (
                        this.hasRole(user, [
                            "ADMIN",
                            "EXECUTIVE_MANAGEMENT"
                        ]) ||
                        this.hasTeam(user, this.context.teamsIndexed[17]) // Patient Financial Services
                    );
                    break;
                case "order.requestPaperworkHelp":
                    /**
                     * Whether or not the user can request paperwork help (Sales
                     * Liaison Meetings).
                     *
                     * Last update to this list was 10/1/24 per Jarrad Rankin.
                     */
                    this.memo[key] = (
                        this.hasRole(
                            user,
                            [
                                "ADMIN",
                                "EXECUTIVE_MANAGEMENT",
                                "SALES",
                                "SALES_LIAISON",
                                "SALES_LIAISON_MANAGER",
                                "SALES_MANAGER",
                                "PATIENT_CARE_REP",
                                "AREA_MANAGER",
                                "OFFICE_MANAGER",
                                "OFFICE_SUPPORT",
                            ]
                        )
                    );
                    break;
                case "order.manualRoute":
                    /**
                     * Whether or not the user can manually route an order.
                     */
                    this.memo[key] = (
                        this.hasRole(user, "ADMIN")
                    );
                    break;
                case "order.cancel":
                    /**
                     * Whether or not the user can manually cancel an order.
                     */
                    this.memo[key] = (
                        this.hasRole(user, [
                            "ADMIN",
                            "BILLING",
                            "CARE_COORDINATOR",
                            "COLLECTIONS",
                            "CUSTOMER_EXPERIENCE",
                            "EXECUTIVE_MANAGEMENT",
                            "FINANCE",
                            "FOLLOWUP",
                            "PATIENT_CARE_REP",
                            "PURCHASING",
                            "SALES",
                            "SALES_LIAISON",
                            "SALES_LIAISON_MANAGER",
                            "SALES_MANAGER",
                            "MARKETING_MANAGER",
                            "SERVICE_TEAM",
                            "VERIFICATION",
                        ])
                    );
                    break;
                case "order.editPatientIssue":
                    /**
                     * Whether or not the user can edit patient issues.
                     */
                    this.memo[key] = (
                        this.hasRole(user, [
                            "ADMIN",
                            "CUSTOMER_EXPERIENCE",
                            "CARE_COORDINATOR",
                            "EXECUTIVE_MANAGEMENT",
                            "OFFICE_MANAGER",
                            "BILLING",
                        ])
                    );
                    break;
                case "order.updateBrightreeId":
                    /**
                     * Whether or not the user can manually update brightree_external_id and bt_so_id on orders/tickets.
                     */
                    this.memo[key] = (
                        this.hasRole(user, [
                            "ADMIN",
                            "BILLING",
                            "EXECUTIVE_MANAGEMENT",
                        ])
                    );
                    break;
                case "patientIssue.createClosePatientCollection":
                    /**
                     * Whether or not the user can create and close patient
                     * issues with the Patient Collections category. This was
                     * specifically asked for and is a Rehab Medical only
                     * permission.
                     */
                    this.memo[key] = (
                        this.hasRole(user, [
                            "ADMIN",
                            "EXECUTIVE_MANAGEMENT"
                        ]) ||
                        this.hasDepartment(user, this.context.departmentsIndexed[8]) // Revenue cycle
                    );
                    break;
                default:
                    this.memo[key] = false;
                    break;
            }
        }

        return this.memo[key];
    }

    /**
     * @param {object} user The user to check.
     * @param {array|string} roles The role(s) to check.
     * @returns {boolean} Whether or not the user has a specific role or roles.
     *
     * Role reference:
     *
     * ADMIN
     * AREA_MANAGER
     * BILLING
     * CARE_COORDINATOR
     * COLLECTIONS
     * CUSTOMER_EXPERIENCE
     * EXECUTIVE_MANAGEMENT
     * FINANCE
     * FOLLOWUP
     * MARKETING
     * MARKETING_MANAGER
     * OFFICE_MANAGER
     * OFFICE_SUPPORT
     * PATIENT_CARE_REP
     * PURCHASING
     * SALES
     * SALES_LIAISON
     * SALES_LIAISON_MANAGER
     * SALES_MANAGER
     * SERVICE_TEAM
     * TECHNICIAN
     * THIRD_PARTY_USER
     * VERIFICATION
     * WAREHOUSE_TECHNICIAN
     */
    static hasRole(user, roles) {
        if (typeof roles === "string") {
            roles = [roles];
        }
        return roles.includes(user.role);
    }

    /**
     * @param {object} user The user to check.
     * @returns {boolean} Whether or not the user is an executive, based on
     * their attachment as an executive on departments.
     */
    static isExecutive(user) {
        return Object.values(this.context.departmentsIndexed).some((department) => {
            return department.executiveId === user.id;
        });
    }

    /**
     * @param {object} user The user to check.
     * @returns {boolean} Whether or not the user is a director, based on their
     * attachment as a director on departments. Also checks regions.
     */
    static isDirector(user) {
        return (
            Object.values(this.context.departmentsIndexed).some((department) => {
                return department.directorId === user.id;
            }) ||
            Object.values(this.context.regionsIndexed).some((region) => {
                return (
                    region.directorUserprofileId === user.id
                );
            })
        );
    }

    /**
     * @param {object} user The user to check.
     * @returns {boolean} Whether or not the user is a manager, based on their
     * attachment as a manager on teams.
     */
    static isManager(user) {
        return Object.values(this.context.teamsIndexed).some((team) => {
            return team.managerId === user.id;
        });
    }

    /**
     * @param {object} user The user to check.
     * @param {object} team The team to check.
     * @returns {boolean} Whether or not the user is on the specified team.
     */
    static hasTeam(user, team) {
        return (
            team?.managerId === user.id ||
            (
                Object.values(this.context.teamUsersIndexed).some((teamUser) => {
                    return (
                        teamUser.userId === user.id &&
                        teamUser.teamId === team.id
                    );
                })
            )
        );
    }

    /**
     * @param {object} user The user to check.
     * @param {object} department The department to check.
     * @returns {boolean} Whether or not the user is on the specified department.
     */
    static hasDepartment(user, department) {
        return (
            department?.directorId === user.id ||
            department?.executiveId === user.id ||
            (
                Object.values(this.context.teamsIndexed).some((team) => {
                    return (
                        team.departmentId === department.id &&
                        this.hasTeam(user, team)
                    );
                })
            )
        );
    }

    /**
     * @param {object} user The user to check.
     * @returns {boolean} Whether or not the user is on a team, based on whether
     * or not at least one row exists in team_user with their user.id.
     */
    static isOnATeam(user) {
        return Object.values(this.context.teamUsersIndexed).some((teamUser) => {
            return teamUser.userId === user.id;
        });
    }

    /**
     * @param {object} user The user to check.
     * @param {string} type The type of prefilter
     * region|location|user|marketingCampaign
     * @returns {boolean} Whether or not the user is on a team that uses
     * prefilters, based on whether or not at least one row exists in team_user
     * with their user.id and that team is on a department with usePrefilters
     * enabled.
     */
    static isOnADepartmentThatUsesPrefilter(user, type) {
        const departments = Object.values(this.context.departmentsIndexed)
            .filter((department) =>
                department.jsonPrefilters?.enabled === true &&
                department.jsonPrefilters?.[type] === true
            );

        for (let i = 0; i < departments.length; i++) {
            if (this.hasDepartment(user, departments[i])) {
                return true;
            }
        }
    }
}