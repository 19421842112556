import React from "react";
import Menu from "./Menu";
import Setting from "../Utility/Setting";
import moment from "moment";

// MUI
import MUIGrid from "@mui/material/Grid";
import MUIIconButton from '@mui/material/IconButton';
import MUIButton from '@mui/material/Button';
import MUITextField from "@mui/material/TextField";
import MUIBox from "@mui/material/Box";
import MUIAddCommentIcon from '@mui/icons-material/AddComment';
import MUIEditCalendarIcon from '@mui/icons-material/EditCalendar';
import MUIArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import MUIChildCareIcon from '@mui/icons-material/ChildCare';
import MUIInputAdornment from "@mui/material/InputAdornment";
import MUISearchIcon from "@mui/icons-material/Search";
import MUIVisibilityIcon from '@mui/icons-material/Visibility';
import MUIRedColor from "@mui/material/colors/red";
import MUIGreenColor from "@mui/material/colors/green";
import MUIBlueColor from "@mui/material/colors/blue";
import MUIGreyColor from "@mui/material/colors/grey";
import MUITooltip from "@mui/material/Tooltip";
import MUIStyled from '@mui/material/styles/styled';
import MUICheckIcon from '@mui/icons-material/Check';
import MUIEditIcon from '@mui/icons-material/Edit';

// DevExpress
import {
    Grid as DevExpressGrid,
    Table as DevExpressTable,
    TableHeaderRow as DevExpressTableHeaderRow,
    PagingPanel as DevExpressPagingPanel,
    TableGroupRow as DevExpressTableGroupRow,
    GroupingPanel as DevExpressGroupingPanel,
    DragDropProvider as DevExpressDragDropProvider,
    Toolbar as DevExpressToolbar,
    TableColumnResizing as DevExpressTableColumnResizing,
    TableSummaryRow as DevExpressTableSummaryRow,
} from "@devexpress/dx-react-grid-material-ui";
import {
    SortingState as DevExpressSortingState,
    IntegratedSorting as DevExpressIntegratedSorting,
    PagingState as DevExpressPagingState,
    IntegratedPaging as DevExpressIntegratedPaging,
    GroupingState as DevExpressGroupingState,
    IntegratedGrouping as DevExpressIntegratedGrouping,
    SummaryState as DevExpressSummaryState,
    IntegratedSummary as DevExpressIntegratedSummary,
    DataTypeProvider as DevExpressDataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
    Getter as DevExpressGetter,
} from '@devexpress/dx-react-core';
export default class DataGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filteredRows: props.rows,
            query: props.query || "",
            filters: {},
        };
    }

    /**
     * Set the search results on mount. This ensures that the list is sorted
     * appropriately on first render.
     */
    componentDidMount() {
        this.setState({
            filteredRows: this.getSearchedAndFilteredRows(this.state.query, this.state.filters),
        });
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.rows.length !== this.props.rows.length ||
            JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows)
        ) {
            this.setState({
                filteredRows: this.getSearchedAndFilteredRows(this.state.query, this.state.filters)
            });
        }
    }

    /**
    * Searches rows in the table. Currently this only supports string and number
    * columns. Other column types can be added as necessary.
    *
    * @param {string} query The search query.
    * @param {object} filters The search filters.
    * @returns A list of rows that match the search.
    */
    getSearchedAndFilteredRows(query, filters) {
        // Filter
        let filteredRows = [];
        if (Object.keys(filters).length === 0) {
            filteredRows = this.props.rows;
        } else {
            this.props.rows.forEach((row) => {
                let filterMatches = true;
                Object.keys(filters).forEach((key) => {
                    if (Object.keys(filters[key]).includes(row[key] ? row[key].toString() : "null") === false) {
                        filterMatches = false;
                    }
                });
                if (filterMatches === true) {
                    filteredRows.push(row);
                }
            });
        }

        // Search
        let searchedAndFilteredRows = [];
        const queryCleaned = query
            .toLowerCase()
            .trim()
            .replace(/\s+/g, ' ');
        if (queryCleaned === '') {
            searchedAndFilteredRows = filteredRows;
        } else {
            filteredRows.forEach((row) => {
                if (Object.values(row).some(value =>
                    String(value).toLowerCase().includes(queryCleaned)
                )) {
                    searchedAndFilteredRows.push(row);
                }
            });
        }

        return searchedAndFilteredRows;
    }

    renderSearchAndMenuBar() {
        const handleChangeSearch = (e) => {
            this.setState({
                query: e.target.value,
                filteredRows: this.getSearchedAndFilteredRows(e.target.value, this.state.filters)
            });
            Setting.set(`queue.${this.props.id}.searchQuery`, e.target.value)
        };

        if (this.state.query === "") {
            Setting.clear(`queue.${this.props.id}.searchQuery`)
        }

        const hasNoResults = this.state.filteredRows.length === 0 && this.state.query !== "";

        const MUIRedBackgroundColor = Setting.get("ui.theme") === "dark" ?  MUIRedColor[500]: MUIRedColor[100];
        const MUIRedBorderColor = Setting.get("ui.theme") === "dark" ? MUIRedColor[100] :  MUIRedColor[400];

        return (
            <MUIGrid container spacing={1}>
                <MUIGrid item xs>
                    <MUITextField
                        value={Setting.get(`queue.${this.props.id}.searchQuery`) || ""}
                        autoFocus={true}
                        placeholder="Type to search..."
                        hiddenLabel={true}
                        fullWidth={true}
                        variant="filled"
                        size="small"
                        sx={{
                            marginBottom: 2,
                            "& .MuiInputLabel-root": { display: "none", height: 0 },
                            "& .MuiFilledInput-root": {
                                backgroundColor: hasNoResults ? MUIRedBackgroundColor : null,
                                borderColor: hasNoResults ?  MUIRedBorderColor  : null,
                                borderStyle: hasNoResults ? "solid" : null,
                                borderWidth: hasNoResults ? "1px" : null,
                                "&:focus-within": {
                                    backgroundColor: hasNoResults ? MUIRedBackgroundColor : null,
                                },
                                "&:hover": {
                                    backgroundColor: hasNoResults ? MUIRedBackgroundColor : null,
                                }
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <MUIInputAdornment position="start">
                                    <MUISearchIcon
                                      sx={{
                                        color: hasNoResults ? MUIRedBorderColor: "inherit"
                                    }}
                                    />
                                </MUIInputAdornment>
                            ),
                            disableUnderline: true,
                            style: { borderRadius: 4 }
                        }}
                        onChange={handleChangeSearch}
                    />
                </MUIGrid>
                {this.props.actions ? (
                    <MUIGrid item xs="auto">
                        <Menu menuItems={this.props.actions} />
                    </MUIGrid>
                ) : null}
            </MUIGrid>
        );
    }

    render() {
        /**
         * FYI moving these outside the render function seems to be the
         * preferred way to do this, but doing so created the weird bug where
         * clearing/changing the groupings would disappear the data grid and
         * scroll it over and it would eventually reappear.
         */

        const DevExpressGridRootStyled = MUIStyled(DevExpressGrid.Root)(({ hideToolbar }) => ({
            height: "100%",
            ...(Setting.get("ui.theme") === "dark" && {
                '.TableContainer-root': {
                    '::-webkit-scrollbar': {
                        backgroundColor: MUIGreyColor[900],
                        width: "16px"
                    },
                    "::-webkit-scrollbar-track": {
                        backgroundColor: MUIGreyColor[900],
                    },
                    "::-webkit-scrollbar-thumb": {
                        background: MUIGreyColor[500],
                        border: `3px solid ${MUIGreyColor[900]}`
                    },
                    "::-webkit-scrollbar-corner": {
                        display: "none !important"
                    },
                },
                '.Pager-pager': {
                    '.Pagination-pagination': {
                        '.Pagination-text': {
                            color: MUIGreyColor[100]
                        }
                    }
                }
            }),
            '.MuiToolbar-root.Toolbar-toolbar': {
                display: hideToolbar ? 'none' : 'flex',  // Conditionally hide the toolbar
            },
            '.MuiTable-root.Table-table': {
                minWidth: '500px !important',
            },
        }));

        const DevExpressGridRoot = (props) => {
            const { hideToolbar, ...otherProps } = props;
            return (
                <DevExpressGridRootStyled
                    {...otherProps}
                    hideToolbar={this.props.hideToolbar}  // Pass hideToolbar as a prop to control visibility
                    onScroll={(e) => {
                        const queueId = new URL(window.location.href).pathname.split("/").pop();
                        window.salesPilot.dataGridScroll[queueId] = {
                            top: e.target.scrollTop,
                            left: e.target.scrollLeft
                        };
                    }}
                    onScrollCapture={(e) => {
                        const queueId = new URL(window.location.href).pathname.split("/").pop();
                        window.salesPilot.dataGridScroll[queueId] = {
                            top: e.target.scrollTop,
                            left: e.target.scrollLeft
                        };
                    }}
                    style={{ height: "100%" }}
                />
            );
        };

        const DevExpressTableStyled = MUIStyled(DevExpressTable.Table)(() => ({
            "tr": {
                height: "40px",
                cursor: "pointer",
            },
            "td": {
                padding: 0,
                fontSize: "12px",
                whiteSpace: "nowrap !important",
                overflow: "hidden !important",
            },
            ".oddLight": {
                backgroundColor: MUIGreyColor[200],
                "&:hover": {
                    backgroundColor: MUIGreyColor[300]
                }
            },
            ".evenLight": {
                backgroundColor: MUIGreyColor[50],
                "&:hover": {
                    backgroundColor: MUIGreyColor[300]
                }
            },
            ".oddLightOutOfBounds": {
                backgroundColor: MUIRedColor[100],
                "&:hover": {
                    backgroundColor: MUIRedColor[300],
                }
            },
            ".evenLightOutOfBounds": {
                backgroundColor: MUIRedColor[200],
                "&:hover": {
                    backgroundColor: MUIRedColor[300],
                }
            },
            ".oddDark": {
                backgroundColor: MUIGreyColor[900],
                "&:hover": {
                    backgroundColor: MUIGreyColor[700]
                }
            },
            ".evenDark": {
                backgroundColor: MUIGreyColor[800],
                "&:hover": {
                    backgroundColor: MUIGreyColor[700]
                }
            },
            ".oddDarkOutOfBounds": {
                backgroundColor: MUIRedColor[800],
                "&:hover": {
                    backgroundColor: MUIRedColor[500],
                }
            },
            ".evenDarkOutOfBounds": {
                backgroundColor: MUIRedColor[600],
                "&:hover": {
                    backgroundColor: MUIRedColor[500],
                }
            },
            ".Container-wrapper": {
                // Removes the background on the group info
                background: "none !important"
            }
        }));

        const TableComponent = props => (
            <DevExpressTableStyled
                {...props}
            />
        );

        let i = 0;

        const TableRowComponent = ({ row, ...restProps }) => {
            let themePart = Setting.get("ui.theme") || "light";
            themePart = themePart.charAt(0).toUpperCase() + themePart.slice(1);

            var className = (i % 2 === 0) ? `even${themePart}` : `odd${themePart}`;
            if (row.out_of_bounds === 'Yes') {
                className += 'OutOfBounds';
            }
            i++;

            return (
                <DevExpressTable.Row
                    {...restProps}
                    className={className}
                    onClick={() => this.props.onClick(row)}
                />
            );
        };

        const TableCellComponent = ({ row, column, ...props }) => {
            // TODO: Can probably clean this up and have the switch only
            // generate the value, then at the end return the table.cell
            // component to avoid the duplication of events etc.
            switch (column.type) {
                case "ageHours":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {`${row[column.name]}h`}
                        </DevExpressTable.Cell>
                    );
                case "ageBusinessHours":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {`${row[column.name]}bh`}
                        </DevExpressTable.Cell>
                    );
                case "ageDays":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {`${Math.floor(row[column.name] / 24)}d`}
                        </DevExpressTable.Cell>
                    );
                case "ageBusinessDays":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {`${Math.floor(row[column.name] / 8)}bd`}
                        </DevExpressTable.Cell>
                    );
                case "ageBusinessHoursDays":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {row[column.name] > 8 ? `${Math.floor(row[column.name] / 8)}bd` : `${row[column.name]}bh`}
                        </DevExpressTable.Cell>
                    );
                case "brightreeExternalId":
                    return (
                        <DevExpressTable.Cell {...props}>
                            <a
                                href={`https://brightree.net/F1/02200/OrbitMedical/Patient/frmPatientSummary.aspx?PatientKey=${row.brightreeExternalKey}&showAck=1`}
                                target="_blank"
                                style={{
                                    fontWeight: "400",
                                    textDecoration: "none",
                                    color: Setting.get("ui.theme") === "dark" ?  MUIBlueColor[200] : MUIBlueColor[700],
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.fontWeight = "600";
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.fontWeight = "400";
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {row[column.name]}
                            </a>
                        </DevExpressTable.Cell>
                    );
                case "edit":
                    return (
                        <DevExpressTable.Cell {...props}>
                            <MUIIconButton>
                                <MUIEditIcon fontSize="small" />
                            </MUIIconButton>
                        </DevExpressTable.Cell>
                    );
                case "points":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {row[column.name] === 0 ? "0" : parseFloat(row[column.name].toFixed(2))}
                        </DevExpressTable.Cell>
                    );
                case "date":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {`${row[column.name] ? moment(row[column.name]).format("M/D/YYYY") : ""}`}
                        </DevExpressTable.Cell>
                    );
                case "dateTime":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {row[column.name] ? moment.utc(row[column.name]).local().format("YYYY-MM-DD h:mma") : null}
                        </DevExpressTable.Cell>
                    );
                case "priority":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {row[column.name] === true ? <MUIArrowCircleUpIcon fontSize="small" sx={{ color: Setting.get("ui.theme") === "dark" && row.out_of_bounds ? MUIRedColor[100] : MUIRedColor[500] }} /> : " "}
                        </DevExpressTable.Cell>
                    );
                case "pediatric":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {row[column.name] === true ? <MUIChildCareIcon fontSize="small" sx={{ color: Setting.get("ui.theme") === "dark" && row.out_of_bounds ? MUIBlueColor[200] : MUIBlueColor[500] }} /> : " "}
                        </DevExpressTable.Cell>
                    );
                case "intakeQuestionnaire":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {row[column.name] ?
                                 <MUIIconButton onClick={(e) => { e.stopPropagation(); this.props.onClickRowAction("openIntakeQuestionnaire", row); }}>
                                     <MUIVisibilityIcon fontSize="small" sx={{ color: Setting.get("ui.theme") === "dark" && row.out_of_bounds ? MUIGreenColor[300] : MUIGreenColor[700] }} />
                                 </MUIIconButton>
                            :
                                <MUIIconButton onClick={(e) => { e.stopPropagation(); this.props.onClickRowAction("openIntakeQuestionnaire", row); }}>
                                    <MUIAddCommentIcon fontSize="small" sx={{ color: Setting.get("ui.theme") === "dark" && row.out_of_bounds ? MUIBlueColor[200] : MUIBlueColor[500] }} />
                                </MUIIconButton>}
                        </DevExpressTable.Cell>
                    );
                case "datePicker":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {row[column.name] ? (
                                <MUIButton
                                    startIcon={<MUIEditCalendarIcon fontSize="small" sx={{ color: Setting.get("ui.theme") === "dark" && row.out_of_bounds ? MUIRedColor[200] : MUIRedColor[500] }} />}
                                    sx={{ color: "inherit", fontWeight: "inherit", fontSize: "inherit" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.onClickRowAction("openDatePicker", row, { columnName: column.name, value: row[column.name] });
                                    }}
                                >
                                    {moment(row[column.name]).format("YYYY-MM-DD")}
                                </MUIButton>
                            ) : (
                                <MUIButton
                                    startIcon={<MUIEditCalendarIcon fontSize="small" sx={{ color: Setting.get("ui.theme") === "dark" && row.out_of_bounds ? MUIRedColor[200] : MUIRedColor[500] }} />}
                                    sx={{ color: "inherit", fontWeight: "inherit", fontSize: "inherit" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.onClickRowAction("openDatePicker", row, { columnName: column.name });
                                    }}
                                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </MUIButton>
                            )}
                        </DevExpressTable.Cell>
                    );
                case "stringTooltip":
                    return (
                        <DevExpressTable.Cell {...props}>
                            <MUITooltip  title={<span style={{ whiteSpace: 'pre-wrap' }}>{row[column.name]}</span>} placement="bottom-start">
                                <span>{row[column.name]}</span>
                            </MUITooltip>
                        </DevExpressTable.Cell>
                    );
                case "booleanCheck":
                    return (
                        <DevExpressTable.Cell {...props}>
                            {row[column.name] ? (<MUICheckIcon fontSize="small" sx={{ color: Setting.get("ui.theme") === "dark" && row.out_of_bounds ? MUIGreenColor[300] : MUIGreenColor[700] }} />) : null}
                        </DevExpressTable.Cell>
                    );
                case "productSubTypes":
                    return (
                        <DevExpressTable.Cell {...props}>
                            <MUITooltip title={row[column.name]} placement="bottom-start">
                                <span>{row[column.name]}</span>
                            </MUITooltip>
                        </DevExpressTable.Cell>
                    );
                case "accountsReceivableBucket":
                    if (row[column.name] === null) {
                        return (
                            <DevExpressTable.Cell {...props}>
                                {null}
                            </DevExpressTable.Cell>
                        );
                    }
                    const daysDifference = moment().diff(moment.utc(row[column.name]), "days");
                    let bucket = "";
                    if (daysDifference <= 30) {
                        bucket = "0-30";
                    } else if (daysDifference <= 60) {
                        bucket = "31-60";
                    } else if (daysDifference <= 90) {
                        bucket = "61-90";
                    } else if (daysDifference <= 120) {
                        bucket = "91-120";
                    } else {
                        bucket = "121+";
                    }
                    return (
                        <DevExpressTable.Cell {...props}>
                            {bucket}
                        </DevExpressTable.Cell>
                    );
                case "rowActions":
                    const menuItems = this.props.rowActions.map((rowAction, i) =>
                        React.cloneElement(rowAction.component, {
                            key: i,
                            onClick: () => {
                                this.props.onClickRowAction(rowAction.rowAction, row);
                            },
                            disabled: rowAction.isDisabled(row)
                        })
                    );

                    return (
                        <DevExpressTable.Cell {...props} onClick={(e) => { e.stopPropagation(); }}>
                            <Menu anchorOriginHorizontal="left" transformOriginHorizontal="left" menuItems={menuItems} />
                        </DevExpressTable.Cell>
                    );
                default:
                    return (
                        <DevExpressTable.Cell
                            {...props}
                        />);
            }
        };

        let groupSummaryItems = this.props.grouping && this.props.grouping.length > 0 ? [
            {
                columnName: this.props.grouping[0].columnName,
                type: 'count',
                showInGroupFooter: false
            },
        ] : [];

        if (this.props.groupSummaryItems) {
            groupSummaryItems = groupSummaryItems.concat(this.props.groupSummaryItems);
        }

        // Custom summary calculator for whatever needs it.
        const summaryCalculator = (type, rows, getValue) => {
            if (type === "sumPoints") {
                let sumPoints = 0;
                for (let i = 0; i < rows.length; i++) {
                    sumPoints += getValue(rows[i]) ? getValue(rows[i]) : 0
                }
                return sumPoints.toFixed(2);
            }

            return DevExpressIntegratedSummary.defaultCalculator(type, rows, getValue);
        };

        // You need this for any custom summary calculations.
        const messages = {
            sumPointsOf: 'Points: ',
        };

        // Formatter for currency or other column types.
        const CurrencyFormatter = ({ value }) => (
            value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        );

        const CurrencyTypeProvider = props => (
            <DevExpressDataTypeProvider
                formatterComponent={CurrencyFormatter}
                {...props}
            />
        );

        // Get a list of currency columns based on their type.
        const currencyColumnNames = this.props.columns.filter((column) => {
            return column.type === "currency"
        }).map((column) => { return column.name });

        // Build the table column extensions. For now just doing this for any
        // column that specifies an alignment.
        const tableColumnExtensions = this.props.columns
            .filter((column) => column.align)
            .map(({ name, align }) => ({ columnName: name, align }));


        // Recursively chunk the flat table body rows into a tree structure.
        const getTree = (rows, groupSummaryValues) => {
            const chunks = [];
            const expectedLevelKey = rows[0].key.replace(rows[0].row.key, "");
            rows.forEach((row) => {
                const thisLevelKey = row.key.replace(row.row.key, "");
                // If the current row is the start of a new chunk at the same level
                if (thisLevelKey === expectedLevelKey) {
                    // If there is a previous chunk and it should be recursively chunked
                    if (
                        chunks[chunks.length - 1] &&
                        chunks[chunks.length - 1].children.length > 0 &&
                        chunks[chunks.length - 1].children[0].rowId === undefined // Is a group
                    ) {
                        chunks[chunks.length - 1].children = getTree(chunks[chunks.length - 1].children, groupSummaryValues);
                    }

                    chunks.push({
                        parent: {
                            row: row,
                            groupSummaryValues: groupSummaryValues[row.row.compoundKey],
                        },
                        children: [],
                    });
                } else {
                    chunks[chunks.length - 1].children.push(row);
                }
            });

            return chunks;
        }

        // Get the order in which to use groupSummaryValues when sorting.
        const getSortOrder = (sortBy, groupSummaryItems) => {
            return sortBy.map((sort) => ({
                index: groupSummaryItems.findIndex((groupSummaryItem) => groupSummaryItem.columnName === sort.columnName),
                direction: sort.direction
            }));
        };

        // Recursively sort the tree.
        const sortTree = (tree, sortOrder) => {
            // Sort the tree by all of the sorts. The index + 1 is because the
            // first value in groupSummaryValues is always the count.
            tree.sort((a, b) => {
                for (let { index, direction } of sortOrder) {
                    const diff = a.parent.groupSummaryValues[index + 1] - b.parent.groupSummaryValues[index + 1];

                    if (diff !== 0) {
                        return direction === "asc" ? diff : -diff; // Apply sorting direction
                    }
                }
                return 0; // Maintain order if all values are equal
            });


            // Recursively sort the children. Only sorts at the group level as
            // the row level is already handled by the datagrid.
            tree.forEach((node) => {
                if (
                    node.children &&
                    node.children.length > 0 &&
                    node.children[0].parent
                ) {
                    node.children = sortTree(node.children, sortOrder)
                }
            });

            return tree;
        };

        // Flatten the row tree back out to a single-dimensional array.
        const flattenSortedTree = (tree) => {
            const rows = [];
            tree.forEach((node) => {
                if (node.parent) {
                    rows.push(node.parent.row);
                    rows.push(...flattenSortedTree(node.children))
                } else {
                    rows.push(node);
                }
            });
            return rows;
        }

        // Sometimes override the order of the rows to do what we want. Namely,
        // sort by a grouped column.
        const getTableBodyRows = ({
            tableBodyRows,
            groupSummaryValues
        }) => {
            // https://supportcenter.devexpress.com/ticket/details/t1014465/reactive-grid-how-to-sort-groups-by-the-group-count
            // https://codesandbox.io/p/sandbox/grid-sort-group-rows-by-summary-e0tr9?file=%2Fdemo.js%3A23%2C1-63%2C3

            if (!this.props.sorting) {
                return tableBodyRows;
            }

            const sortBy = this.props.sorting
                .filter(sortItem =>
                    this.props.groupSummaryItems.some(groupSummaryItem => groupSummaryItem.columnName === sortItem.columnName)
                )
                .map(sortItem => ({
                    columnName: sortItem.columnName,
                    direction: sortItem.direction
                }));

            // If nothing to sort by, just return the original rows (which are
            // already sorted by whatever other criteria exists).
            if (sortBy.length === 0) {
                return tableBodyRows;
            }

            const tree = getTree(tableBodyRows, groupSummaryValues);
            const sortOrder = getSortOrder(sortBy, this.props.groupSummaryItems);
            const sortedTree = sortTree(tree, sortOrder);
            const sortedTableBodyRows = flattenSortedTree(sortedTree);

            return sortedTableBodyRows;
        };

        /**
         * Forgive me for I have sinned. This monstrosity allows maintaining the
         * scroll position of the data grid. You can't use state because that
         * calls componentDidUpdate. You can't use this.* because that gets
         * cleared when the grid is reloaded. So instead this just stores this
         * globally on the window object per "queue" (or really ID; this
         * component has no knowledge of the queue concept).
         *
         * Anyways, when the grid is scrolled the position is saved. Then after
         * the grid renders we reach back into the DOM and set the scroll
         * position. The timeout ensures everything has rendered.
         */
        setTimeout(() => {
            const element = document.querySelector(".TableContainer-root");
            const queueId = new URL(window.location.href).pathname.split("/").pop();
            if (element && window.salesPilot.dataGridScroll[queueId]) {
                element.scrollTop = window.salesPilot.dataGridScroll[queueId].top;
                element.scrollLeft = window.salesPilot.dataGridScroll[queueId].left;
            }
        }, 0);

        return (
            <>
                {this.props.enableGroupingPanel ? (
                    <>
                        <MUIBox sx={{ height: "100%" }}>
                            <MUIBox sx={this.props.compactTopRightSearchbarAndMenuLayout ? { display: 'none' } : { mt: 1 }}>
                                {this.renderSearchAndMenuBar()}
                            </MUIBox>
                            <DevExpressGrid
                                rows={this.state.filteredRows}
                                columns={this.props.columns}
                                rootComponent={DevExpressGridRoot}
                            >
                                <CurrencyTypeProvider
                                    for={currencyColumnNames}
                                />
                                <DevExpressSortingState
                                    sorting={this.props.sorting}
                                    onSortingChange={this.props.onSortingChange}
                                />
                                <DevExpressIntegratedSorting />
                                <DevExpressGroupingState
                                    grouping={this.props.grouping}
                                    onGroupingChange={this.props.onGroupingChange}
                                    expandedGroups={this.props.expandedGroups}
                                    onExpandedGroupsChange={this.props.onExpandedGroupsChange}
                                />
                                <DevExpressSummaryState
                                    groupItems={groupSummaryItems}
                                />
                                <DevExpressIntegratedGrouping/>
                                <DevExpressIntegratedSummary calculator={summaryCalculator} />
                                <DevExpressPagingState defaultCurrentPage={0} pageSize={100} />
                                <DevExpressIntegratedPaging />
                                <DevExpressDragDropProvider />
                                <DevExpressTable
                                    // Performance note: This TableCellComponent seems to be a large source of performance issues.
                                    cellComponent={TableCellComponent}
                                    tableComponent={TableComponent}
                                    rowComponent={TableRowComponent}
                                    height="auto"
                                    columnExtensions={tableColumnExtensions}
                                />
                                <DevExpressTableColumnResizing defaultColumnWidths={this.props.defaultColumnWidths} />
                                <DevExpressTableHeaderRow showSortingControls={true} />
                                <DevExpressPagingPanel />
                                <DevExpressTableGroupRow
                                    showColumnsWhenGrouped={true}
                                    messages={messages}
                                    summaryItemComponent={(props) => (props.children)} // This summaryItemComponent is here to remove the obscene "Sum:" etc prefix from the summaries.
                                />
                                <DevExpressTableSummaryRow/>
                                <DevExpressGetter name="tableBodyRows" computed={getTableBodyRows} />
                                <DevExpressToolbar />
                                <DevExpressGroupingPanel showGroupSortingControls={this.props.showGroupSortingControls} showGroupingControls={true} />
                            </DevExpressGrid>
                            <MUIBox sx={this.props.compactTopRightSearchbarAndMenuLayout ? { position: "absolute", top: "12px", right: "12px", width: "40%" } : { display: 'none' }}>
                                {this.renderSearchAndMenuBar()}
                            </MUIBox>
                        </MUIBox>
                    </>
                ) : (
                    <>
                        <MUIBox sx={{ height: "100%" }}>
                            <MUIBox sx={this.props.compactTopRightSearchbarAndMenuLayout ? { display: 'none' } : { mt: 1 }}>
                                {this.renderSearchAndMenuBar()}
                            </MUIBox>
                            <DevExpressGrid
                                rows={this.state.filteredRows}
                                columns={this.props.columns}
                                rootComponent={DevExpressGridRoot}
                            >
                                <DevExpressSortingState
                                    sorting={this.props.sorting}
                                    onSortingChange={this.props.onSortingChange}
                                />
                                <DevExpressIntegratedSorting />
                                <DevExpressPagingState defaultCurrentPage={0} pageSize={100} />
                                <DevExpressIntegratedPaging />
                                <DevExpressTable
                                    // Performance note: This TableCellComponent seems to be a large source of performance issues.
                                    cellComponent={TableCellComponent}
                                    tableComponent={TableComponent}
                                    rowComponent={TableRowComponent}
                                    height="auto"
                                />
                                <DevExpressTableColumnResizing defaultColumnWidths={this.props.defaultColumnWidths} />
                                <DevExpressTableHeaderRow showSortingControls />
                                <DevExpressPagingPanel />
                            </DevExpressGrid>
                            <MUIBox sx={this.props.compactTopRightSearchbarAndMenuLayout ? { position: "absolute", top: "12px", right: "12px", width: "40%" } : { display: 'none' }}>
                                {this.renderSearchAndMenuBar()}
                            </MUIBox>
                        </MUIBox>
                    </>
                )}
            </>
        );
    }


}