import React from "react";
import PropTypes from "prop-types";
import Dialog from "../Dialog";
import MUIButton from "@mui/material/Button";
import MUIDialogActions from "@mui/material/DialogActions";
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUITypography from "@mui/material/Typography";
import MUIGrid from "@mui/material/Grid";
import MUITextField from "@mui/material/TextField";
import MUIAlert from '@mui/material/Alert';
import Validator from "../../Utility/Validator";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";


export default class DialogUpdateBrightreeExternalId extends Dialog {
    constructor(props) {
        super(props);
        this.state = {
            order: props.order,
            loading: false,
            validationIssues: {},
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };


    componentDidUpdate(prevProps) {
        if (prevProps.order !== this.props.order) {
            this.setState({ order: this.props.order });
        }
    }

    /**
     * Render header title of dialog
     */
    renderHeader() {
        return (
            <MUITypography variant="h6" color="textPrimary">
                Update Brightree ID
            </MUITypography>
        );
    }

    /**
     * Render search result tiles in a grid.
     */
    renderContent() {
        return (
            <>
                {this.renderAlert()}
                {this.renderBrightreeIdTextField()}
            </>
        )
    }

    renderBrightreeIdTextField() {
        const handleChange = (order) => {
            this.setState({
                order: {
                    ...this.state.order,
                    ...order,
                    brightreeExternalId: order.btSoId,
                },
            });
        }

        return (
            this.state.order ? (
                <MUIGrid item lg={6} md={12} sm={12}>
                    <MUITextField
                        defaultValue={this.state.order && this.state.order.btSoId ? this.state.order.btSoId : ""}
                        size="small"
                        label="Brightree ID"
                        variant="standard"
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                          }}
                        onBlur={(e) => {
                            let value = e.target.value.trim();
                            if (value === "") {
                                handleChange({ btSoId: null });
                            } else if (/^\d+$/.test(value)) {
                                handleChange({ btSoId: parseInt(value) });
                            } else {
                                handleChange({ btSoId: value });
                            }
                        }}
                        onChange={(e) => {
                            const newValue = e.target.value.replace(/[^0-9]/g, '');
                            handleChange({ btSoId: newValue });
                        }}
                        error={this.state.validationIssues.btSoId ? true : false}
                        helperText={this.state.validationIssues.btSoId ? this.state.validationIssues.btSoId : ""}
                    />
                </MUIGrid>
            ) : null
        );
    }


    renderAlert() {
        return (
            <MUIAlert
                severity="info"
                sx={{ mb: 2 }}
            >      If this order should link to a different Sales Order in Brightree, please&nbsp;update&nbsp;below.</MUIAlert>
        )
    }

    /**
     * Render cancel and confirm button actions of dialog.
     */
    renderActions() {
        const validateSave = () => {
            const validationIssues = Validator.validate(
                this.state.order,
                {
                    btSoId: {
                        'type': 'integer',
                    },
                }
            );

            this.setState({
                validationIssues: validationIssues,
            });

            if (Object.keys(validationIssues).length === 0) {
                this.props.onConfirm(this.state.order);
            } else {
                NotistackEnqueueSnackbar("Please correct the displayed issues.", { variant: "error" });
            }
        }

        return (
            <MUIDialogActions spacing={2}>
                <MUIButton variant="text" onClick={this.props.onClose}>
                    Cancel
                </MUIButton>
                <MUILoadingButton
                    variant="contained"
                    loading={this.state.loading}
                    onClick={() => validateSave()}
                >
                    Update Brightree ID
                </MUILoadingButton>
            </MUIDialogActions>
        );
    }


    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "sm";
    }
}