import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    MDBBtn,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBCard,
    MDBListGroup,
    MDBListGroupItem,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBRow,
    MDBTabContent,
    MDBTabPane,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table,
    TableBody,
    TableHead,
    Timeline,
    TimelineStep,
    toast,
    ToastContainer,
    MDBInput,
    MDBTable,
    MDBCardHeader,
    MDBSpinner,
    MDBSelect,
} from "mdbreact";
import moment from "moment";
import classnames from "classnames";
import OrderService from "../Security/OrderService/orderService";
import partsService from "../Security/ProductService/partsService";
import NoteService from "../Security/NoteService/noteService";
import "./patientView.css";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import "react-dual-listbox/lib/react-dual-listbox.css";
import PurchaseQueueService from "../Security/PurchaseQueueService/purchaseQueueService";
import PqQuoteSelector from "./pqQuoteSelector";
import LiaisonMeetingService from "../Security/LiaisonMeetingService/LiaisonMeetingService";
import PatientInformationTab from "./PatientViewComponents/patientInformationTab";
// import FollowupsTab from "./PatientViewComponents/followupsTab";
import NotesTab from "./PatientViewComponents/notesTab";
import AttachmentsTab from "./PatientViewComponents/attachmentsTab";
import TypeStatusContainer from "./PatientViewComponents/typeStatusContainer";
import LiaisonModal from "./PatientViewComponents/liaisonModal";
import CreateNoteModal from "./PatientViewComponents/createNoteModal";
import PatientCommunicationService from "../Settings/PatientCommunicationService";
import HomeAssessmentModal from "./PatientViewComponents/HomeAssessmentModal";
import CreateQuoteModal from "./PatientViewComponents/CreateQuoteModal";
import GlobalFunctions from "../Filters/GlobalFunctions";
import AccountService from "../Security/AccountService/accountService";
import prodcutMatrixEntryService from "../Security/ProdcutMatrixEntryService/prodcutMatrixEntryService";
import MUIButton from "@mui/material/Button";
import MUIDialog from '@mui/material/Dialog';
import MUIDialogActions from '@mui/material/DialogActions';
import MUIDialogContent from '@mui/material/DialogContent';
import MUIDialogContentText from '@mui/material/DialogContentText';
import MUIDialogTitle from '@mui/material/DialogTitle';
import MUITextfield from "@mui/material/TextField";
import MUIIconButton from "@mui/material/IconButton";
import MUIEditIcon from '@mui/icons-material/Edit';
import BtProductService from "../Security/BtProductService/btProductService";
import Security from "../../Utility/Security";
import LayerLegacy from "../../LayerLegacy";
import * as Sentry from "@sentry/browser";
import ApprovalSummary from "../../Component/ApprovalSummary";
import DialogUpdateBrightreeExternalId from "../../Component/Dialog/DialogUpdateBrightreeExternalId";

export default class PatientOrderView extends LayerLegacy {
    constructor(props) {
        super(props);

        let id,
            isLoaded = false,
            order = {},
            goto = "/order";

        if (props.match && props.match.params) {
            id = props.match.params.id;
        }

        if (
            props.location &&
            props.location.state &&
            props.location.state.goto
        ) {
            goto = props.location.state.goto;
        }

        // leaving this just in case it's needed later
        // let dt = new Date(),
        // 		mo =
        // 			dt.getMonth() < 10
        // 				? "0" + (dt.getMonth() + 1).toString()
        // 				: dt.getMonth() + 1,
        // 		dy = dt.getDate() < 10 ? "0" + dt.getDate().toString() : dt.getDate(),
        // 		ho = dt.getHours() < 9 ? "0" + dt.getHours().toString() : dt.getHours(),
        // 		mi =
        // 			dt.getMinutes() < 9
        // 				? "0" + dt.getMinutes().toString()
        // 				: dt.getMinutes(),
        // 		se =
        // 			dt.getSeconds() < 9
        // 				? "0" + dt.getSeconds().toString()
        // 				: dt.getSeconds(),
        // 		name = `${id}_${dt.getFullYear()}${mo}${dy}_${ho}${mi}${se}_MergedDocuments`;

        let primaryColumns = [
            {
                label: "Category",
                field: "category",
                // minimal: "sm",
            },
            {
                label: "Product",
                field: "product",
                // minimal: "lg",
            },
            {
                label: "HCPCS",
                field: "hcpcs",
                // minimal: "sm",
            },
            {
                label: "Points",
                field: "points",
                // minimal: "sm",
            },
            {
                label: "Quantity",
                field: "quantity",
                // minimal: "sm",
            },
            {
                label: "Serial Number",
                field: "serialnumber",
                // minimal: "lg",
            },
        ];

        let secondaryColumns = [
            {
                label: "btID",
                field: "btitemId",
            },
            {
                label: "Approved",
                field: "approved",
                // minimal: "lg",
            },
            {
                label: "ABN Required",
                field: "abnrequired",
                // minimal: "lg",
            },
            {
                label: "Remove",
                field: "Remove",
                // minimal: "lg",
            },
        ];

        const data = {
            columns: primaryColumns.concat(secondaryColumns),
            rows: [],
        };

        this.state = {
            id: id,
            notes: [],
            clinicNotes: [],
            tasks: [],
            order: {},
            goto: goto,
            eOrder: {},
            isLoaded: isLoaded,
            noteModalOpen: false,
            timelineModalOpen: false,
            approvalModalOpen: false,
            followupModalOpen: false,
            approvalErrorsModal: false,
            approvalErrors: [],
            noteText: "",
            noteAssignedTo: order.activity ? order.activity.owner : null,
            taskCompleted: true,
            clinicTaskCompleted: true,
            chosenCategory: null,
            statuses: [
                { label: "New", value: "New" },
                { label: "In Process", value: "InProcess" },
                { label: "Set Up", value: "Setup" },
                { label: "Cancelled", value: "Cancelled" },
            ],
            orderTypes: [
                "New",
                "Pickup",
                "Service",
                "Exchange",
                "Internal Request",
                "Re-Supply",
            ],
            data: data,
            abnModal: false,
            approveAgreement: false,
            approveAuthNotRequired: false,
            approveWithoutAuth: false,
            allApproved: false,
            formActivePanel1: 1,
            formActivePanel1Changed: false,
            totalPoints: 0,
            productOverrides: [],
            purchaseQueueArr: [],
            pqModal: false,
            showOrderProduct: false,
            purchaseQueues: [],
            selectedTime: "",
            primaryColumns: primaryColumns,
            secondaryColumns: secondaryColumns,
            usedLiaisonKeys: [],
            resultingLiaisonKeys: [],
            liaisonFullKeys: [],
            stateSalesTax: [],
            followUpDays: 5,
            productTypes: [],
            productSubTypes: [],
            isPediatric: false,
            homeAssessmentModalOpen: false,
            helpModalOpen: false,
            saveProcessStarted: false,
            disableOrderSave: false,
            orderNotFound: false,
            idNames: [],
            idsLoaded: false,
            BTIDModal: false,
            btitems: null,
            allProductSubTypes: [],
            fieldNotesOpen: false,
            createQuoteOpen: false,
            createQuoteSmall: false,

            orderProducts: [],
            updateProducts: [],
            productsLoaded: false,
            updatingProducts: false,

            pricesheets: [],
            isAutoVerification: null,
            lastChartNotes: null,
            fullLiaisonKeysTest: [],

            setProductDialogOpen: false,
            curProduct: {},
            curIndex: null,

            baseUnitVendor: null,
            selectedBaseUnit: null,
            uSerial: null,
            reloadAttachments: false,
            collapseID: "collapse1",
            
            dialogUpdateBrightreeExternalIdOpen: false,
        };

        this.state.TabsOneActiveItem = "1";
        this.state.TabsTwoActiveItem = "1";

        this.retrieveInitialInfo(id);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let id = nextProps.match.params.id;

        if (nextProps.match.params.id !== this.state.id) {
            var d = this.state.data;
            d.rows = [];
            this.setState({
                id: id,
                isLoaded: false,
                data: d,
            });
            this.retrieveInitialInfo(id);
        }
    }

    retrieveInitialInfo(id) {
        if (
            // this.state.order === {} ||
            !this.state.order.id ||
            this.state.id !== id ||
            this.state.order.id !== id
        ) {
            this.retrieveOrder(id);
        } else {
            //setTimeout(() => this.updateTableRows(false), 100);
            this.setState({
                isLoaded: true,
            });
        }
        this.retrieveNotes(id);
        // this.retrieveFollowUpNotes(id);
        //this.retrieveClinicNotes(id);
        this.retrievePurchaseQueues(id);
        //this.formatStatus();
        //this.getOrderProducts(id);
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        companyName: PropTypes.string,
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        productTypes: PropTypes.array,
        productSubTypes: PropTypes.array,
        reasons: PropTypes.array,
        therapists: PropTypes.array,
        salesReps: PropTypes.array,
        accounts: PropTypes.array,
        allLocations: PropTypes.array,
        internalLocations: PropTypes.array,
        fullLiaisonKeys: PropTypes.array,
        documentTypes: PropTypes.array,
        techs: PropTypes.array,
        marketingCampaigns: PropTypes.array,
        productOverrides: PropTypes.array,
        timezoneVariation: PropTypes.object,
        stateSalesTax: PropTypes.array,
        patientCommunicationTriggerDefs: PropTypes.array,
        allAtp: PropTypes.array,
        allLiaisons: PropTypes.array,
        allVendors: PropTypes.array,
        allBaseUnits: PropTypes.array,
        allBaseUnitTypes: PropTypes.array,
    };

    componentDidMount() {
        this.triggerSerialsPopup();
        //window.addEventListener('beforeunload', (event) => {
        //    event.preventDefault();
        //    this.clearBeingWorkedOn();
        //});
        //this.retrieveInitialInfo(this.state.id);

        this.getCategoryList();
        this.renderProductList();
        this.getServiceQuoteByOrder(this.state.id);
    }

    //componentWillUnmount() {
    //    //this.clearBeingWorkedOn();
    //}

    clearBeingWorkedOn() {
        const { currentUser } = this.context;
        //let order = this.state.order;// || this.state.eOrder;
        let order = this.state.eOrder;
        if (order.currentlyBeingWorkedById === currentUser.id) {
            //order.currentlyBeingWorkedById = null;
            OrderService.clearBeingWorkedBy(order.id)
                .then((data) => { })
                .catch((err) => { });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { productOverrides, productTypes, productSubTypes } =
            this.context;
        if (
            productOverrides &&
            productOverrides.length !== prevState.productOverrides.length
        ) {
            this.setState({
                productOverrides: productOverrides,
            });
        }
        if (productTypes.length !== this.state.productTypes.length) {
            this.getCategoryList();
        }
        if (productSubTypes.length !== this.state.allProductSubTypes.length) {
            this.renderProductList();
        }
    }

    setUserRef() {
        const { currentUser } = this.context;

        return {
            name: currentUser.username,
            id: currentUser.id,
        };
    }

    getOrderProducts(id) {
        let updateProducts = this.state.updateProducts;
        OrderService.getOrderProducts(id)
            .then(data => {
                if (updateProducts && updateProducts.length > 0 && data) {
                    updateProducts.forEach(p => {
                        if (p.product.id && p.product.id > 0) {
                            // match on id
                            var prod = data.find(x => x.id === p.product.id);

                            if (prod) {
                                p.index = data.indexOf(prod);
                                // console.log(p.index)
                                data[p.index] = p.product;
                            }
                            else {
                                data.push(p.product);
                                p.index = data.length - 1;
                            }
                        }
                        else {
                            // no id, so just add it to the list
                            data.push(p.product);
                            p.index = data.length - 1;
                        }
                    });
                }
                this.setState({
                    orderProducts: data,
                    updateProducts: updateProducts,
                }, () => this.updateTableRows());
            })
            .catch(err => {
                console.log(err);
            });
    }

    getAccountPricesheets() {
        AccountService.GetAccountPricesheets(this.state.eOrder.activity.accountId)
            .then(res => {
                this.setState({
                    pricesheets: res,
                });
            })
            .catch(ex => {
                console.log(ex);
            });
    }


    getServiceQuoteByOrder(id) {
        // console.log(id)
        if (id == null || id === "") {
            return;
        }
        partsService.getServiceQuoteByOrder(id)
            .then((r) => {
                // console.log(r, JSON.parse(r.documentData))
                this.setState({
                    serviceQuoteFromOrderId: r,
                });
            })
            .catch((e) => {
                console.log(e);
                this.setState({});
            });
    }

    toggleNoteModalPopup() {
        let currentState = this.state.noteModalOpen;

        this.setState({ noteModalOpen: !currentState });
        if (currentState === true) {
            this.setState({
                noteText: "",
            });
        }

        if (currentState === false) {
            this.setState({ selectedVerificationNoteRadio: null })
            setTimeout(() => {
                let p = document.getElementById("newActivityNoteText");
                if (p) {
                    p.focus();
                }
            }, 600);
        }
    }

    toggleCreateQuoteModalPopup() {
        if (this.state.createQuoteSmall) {
            this.setState({
                createQuoteSmall: !this.state.createQuoteSmall
            });
            return;
        }

        //if (this.state.eOrder.serialNumber !== null && this.state.eOrder.serialNumber && this.state.eOrder.currentModel !== null && this.state.eOrder.currentModel !== "") {
        this.setState({
            createQuoteOpen: !this.state.createQuoteOpen,
        });
        //} else {
        //    toast.error("Serial number and current model required.")
        //}
    }

    toggleFieldNotesModalPopup() {
        this.setState({
            fieldNotesOpen: !this.state.fieldNotesOpen,
        });
    }


    //region liaison meeting stuff
    checkForExistingMeeting(meetings) {
        let existingMeeting = null;
        if (meetings && meetings.length > 0) {
            existingMeeting = meetings.find((meeting) => meeting.scheduled && meeting.scheduled.orderId === this.state.eOrder.id);
        }
        this.setState({
            existingMeeting: existingMeeting ? existingMeeting.scheduled : null,
            helpModalOpen: true,
            saveProcessStarted: false,
        });
    }
    getLiaisonMeetings() {
        LiaisonMeetingService.findAllMeetingTimes()
            .then((r) => {
                this.setState({ liaisonFullKeys: r });
                this.checkForExistingMeeting(r);

            })
            .catch((e) => {
                this.setState({
                    saveProcessStarted: false,
                });
            });
    }



    getVendorList() {
        const { allVendors, allBaseUnits } = this.context;
        let vendors = allVendors;

        let a = [];

        vendors = vendors.filter((v) => allBaseUnits.find((b) => b.vendorId === v.id));

        vendors.map((v) => {
            return a.push({
                label: v.name,
                value: v.id,
            });
        });
        return a;
    }

    getBaseUnitList(baseUnits) {
        let a = [];
        baseUnits.map((b) => {
            return a.push({
                label: b.name,
                value: b.id,
            });
        });
        return a;
    }

    formatTimelineStatus(timelineEntry) {
        if (timelineEntry.status === "ReadyToDeliver") {
            return "Waiting for Delivery";
        } else if (timelineEntry.status === "InProcess") {
            if (timelineEntry.reason === "Verification") {
                return "Intake / Insurance Verification";
            } else {
                return "Insurance Review";
            }
        } else {
            return timelineEntry.status;
        }
    }

    formatStatus = (status) => {
        const statuses = this.state.statuses;
        let i = statuses.findIndex((x) => x.value === status);
        if (statuses[i] !== undefined) {
            return statuses[i].label;
        }
    };

    addNoteUpdateOrderCloseModal(note, order) {
        let notes = this.state.notes;

        notes.unshift(note);
        this.setState({
            notes: notes,
            //eOrder: order,
            noteModalOpen: false,
            isAutoVerification: null,
        });
        this.saveOrder(order);
    }

    //region notes download
    noteAdded(nNote) {
        let notes = this.state.notes;//,
        //o = this.state.eOrder;

        notes.unshift(nNote);
        this.setState({
            notes: notes,
            isAutoVerification: null,
        });
        //this.saveOrder(o);
    }

    downloadNotes() {
        let exportName = this.state.eOrder.id + "_notes_.csv";

        this.clientCSV(this.state.notes, exportName);
    }

    clientCSV(stateData, filename) {
        let result,
            headers = ["Created At", "Created By", "Text"],
            data;

        data = stateData || null;
        if (data == null || !data.length) {
            return null;
        }

        result = "";
        result += headers.join(",");
        result += "\n";

        data.forEach(function (item) {
            // let date = new Intl.DateTimeFormat("en-US").format(new Date(item.createdAt));
            let date = GlobalFunctions.formatDateTime(item.createdAt);
            // this is a slight pain because of complex objects, otherwise we could use keys -CD
            result +=
                date + "," + item.createdBy.username + ',"' + item.text + '"\n';
        });

        this.downloadBlob(result, filename);
    }

    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a");

            link.href = URL.createObjectURL(blob);
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    //endregion

    checkBoxApprovedChange = (event) => {
        this.setState({ approveAgreement: event.target.checked });
    };

    checkBoxApprovedAuthNotRequired = (event) => {
        this.setState({
            approveAuthNotRequired: event.target.checked
        });
    };

    checkBoxApprovedWithoutAuth = (event) => {
        this.setState({ approveWithoutAuth: event.target.checked });
    };

    //region primary order functions
    retrieveNotes(id) {
        NoteService.getNotes(id)
            .then((notes) => {
                let no = notes;

                if (no === null || no.length === 0) {
                    //we have no notes, return...BC
                    this.setState({ notes: [] });
                    return;
                }
                //copy the notes array...BC
                let con = no.slice();

                //sort the notes...BC
                con.sort((a, b) => {
                    if (a.createdAt > b.createdAt) {
                        return -1;
                    } else {
                        return 1;
                    }
                });

                //set the state for notes...BC
                this.setState({ notes: con });
                // this.triggerTaskPopup();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async retrievePurchaseQueues(id) {
        return PurchaseQueueService.findPurchaseQueuesByOrderId(id)
            .then((res) => {
                this.setState({
                    purchaseQueues: res,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    retrieveClinicNotes(id) {
        NoteService.getTherapistNotes(id)
            .then((notes) => {
                if (
                    notes.therapistNotes === null ||
                    notes.therapistNotes.length === 0
                ) {
                    //we have no notes, return...BC
                    return;
                }
                //copy the notes array...BC
                let con = notes.therapistNotes.slice();

                //sort the notes...BC
                con.sort((a, b) => {
                    if (a.createdAt > b.createdAt) {
                        return -1;
                    } else {
                        return 1;
                    }
                });

                //set the state for notes...BC
                this.setState({ clinicNotes: con });
                // this.triggerTaskPopup(true);
            })
            .catch((err) => console.log(err));
    }

    retrieveOrder(id) {
        return OrderService.getFlatOrderActivity(id)
            .then((data) => {
                // if (data.orderType && data.orderType.toLowerCase() === "service") {
                // TODO Uncomment for redirecting the order page to the patient page for pickup tickets
                if (data.orderType && (data.orderType.toLowerCase() === "service" || (data.orderType.toLowerCase() === "pickup" && data.activity.type === "Ticket"))) {

                    this.props.history.push({
                        pathname: "/patientInformation/" + data.patientId + "/" + data.id,
                    });
                    return;
                }
                let dt = new Date(),
                    db =
                        data.dateOfBirth || data.patient.dateOfBirth
                            ? new Date(data.dateOfBirth || data.patient.dateOfBirth)
                            : null,
                    nd = new Date(
                        dt.getFullYear() - 18,
                        dt.getMonth(),
                        dt.getDate()
                    ),
                    isPediatric = nd <= db;
                if (
                    !data.orderActivityApprovals ||
                    data.orderActivityApprovals.length === 0
                )
                    data.orderActivityApprovals = [{}];

                this.setState({
                    order: data,
                    eOrder: JSON.parse(JSON.stringify(data)),
                    isLoaded: true,
                    noteAssignedTo: data.activity.owner,
                    isPediatric: isPediatric,
                }, () => {
                    this.getPatientLastChartNotes(data.patientId);
                    this.getOrderProducts(id);
                    this.getAccountPricesheets();
                });
                //setTimeout(() => this.updateTableRows(false), 100);
            }).catch((err) => console.log(err));
    }

    getPatientLastChartNotes(patientId) {
        OrderService.getPatientLastChartNotes(patientId)
            .then(res => {
                this.setState({
                    lastChartNotes: res,
                });
            })
            .catch(ex => {
                console.log(ex);
            });
    }

    toggleHomeAssessmentModal() {
        if (!this.state.homeAssessmentModalOpen) {
            this.getHomeAssessmentFromWeb();
        } else {
            this.setState({
                homeAssessmentModalOpen: !this.state.homeAssessmentModalOpen,
            });
        }

    }
    getHomeAssessmentFromWeb() {

        let homeAssessmentFromWeb = null;
        OrderService.getHomeAssessmentsFromWeb(this.state.eOrder.id).then((res) => {
            homeAssessmentFromWeb = res;
            this.setState({
                homeAssessmentFromWeb: res,
                homeAssessmentModalOpen: true
            });
        }).catch((err) => {
            this.setState({
                homeAssessmentModalOpen: true
            });
        });
    }

    updateOrderProperties(order, patient) {
        //update address properties
        order.address = patient.address;
        order.city = patient.city;
        order.state = patient.state;
        order.zipCode = patient.zipCode;
        order.phone = patient.phone;

        order.patientName = patient.firstName + " " + patient.lastName;
    }

    generateScheduledNote(order, edit) {
        const { currentUser } = this.context;

        let cb = { id: currentUser.id, name: currentUser.username },
            currentNotes = this.state.notes;

        if (
            edit.orderStatusReason &&
            edit.orderStatusReason.description === "Scheduled"
        ) {
            if (edit.technician && edit.scheduledDeliveryDate) {
                let createNote = false;

                if (!order.technician) {
                    createNote = true;
                } else if (
                    edit.technician.id !== order.technician.id ||
                    edit.scheduledDeliveryDate !== order.scheduledDeliveryDate
                ) {
                    createNote = true;
                }

                if (createNote === true) {
                    let text =
                        "Order was scheduled by " +
                        currentUser.username +
                        " for " +
                        GlobalFunctions.formatDateTimeTimeZone(
                            edit.scheduledDeliveryDate
                        ) +
                        " with technician " +
                        edit.technician.firstname +
                        " " +
                        edit.technician.lastname;

                    let note = {
                        type: "ActivityNote",
                        text: text,
                        createdAt: GlobalFunctions.getUTCMoment(),
                        accountId: edit.activity.accountId,
                        task: false,
                        completed: true,
                        createdById: cb.id,
                        activityId: edit.id,
                    };

                    NoteService.createActivityNote(edit.id, note)
                        .then((res) => {
                            currentNotes.unshift(res.addNote);
                        })
                        .catch((err) => {
                            //Maybe send an error to the user?...BC
                        });
                }
            }
        }
    }

    determineUpdateFollowUpDate(order, edit) {
        //Adding 7 days is the same as 5 business days
        let dt = new Date();
        dt.setDate(dt.getDate() + 7);

        if (dt.getDay() === 6) {
            dt.setDate(dt.getDate() + 1);
        }
        if (dt.getDay() === 0) {
            dt.setDate(dt.getDate() + 1);
        }

        if (![6, 60].includes(order.orderStatusReasonId)) {
            if ([6, 60].includes(edit.orderStatusReasonId) ||
                (edit.orderStatusReason &&
                    edit.orderStatusReason.id === 60
                )) {
                edit.followUpDate = dt;
            }
        }

    }

    syncToBrightTree(orderId) {
        OrderService.syncOrderWithBT(orderId)
            .then((data) => {
                toast.success("Patient order Created in Brighttree");
                return true;
            })
            .catch((err) => {
                toast.error(
                    "Oh no! An error occurred while creating the patient in Brighttree."
                );
                return false;
            });
    }

    async determineStatusTrigger(order) {
        const { patientCommunicationTriggerDefs } = this.context;

        function convertStatus(status) {
            switch (status) {
                case "New":
                    return 0;
                case "InProcess":
                    return 1;
                case "ReadyToDeliver":
                    return 2;
                case "Setup":
                    return 3;
                case "Cancelled":
                    return 4;
                default:
                    break;
            }
        }

        let sid = convertStatus(order.status),
            rid = order.orderStatusReason
                ? order.orderStatusReason.id
                : order.orderStatusReasonId;

        let trigger = patientCommunicationTriggerDefs.find((v) => {
            return v.statusID === sid && v.reasonID === rid;
        });

        if (trigger) {
            let p = {
                orderId: order.id,
                patientId: order.patient.id,
                dateToSend: "",
                sentOn: "",
                type: order.patient.preferredContactMethod,
                statusId: sid,
                reasonId: rid,
                sent: false,
            };

            PatientCommunicationService.insertPatientCommunication(p);
        }
    }

    async saveOrder(order) {
        const { currentUser } = this.context;
        const regex = /^\(\d{3}\) \d{3}-\d{4}$|^\(\d{3}\)-\d{3}-\d{4}$/;

        this.setState({
            saveProcessStarted: true,
        });

        let st = this.state,
            eO = order || st.eOrder,
            ePatient = st.eOrder.patient,
            originalOrder = st.order,
            shouldSave = true,
            notes = st.notes;


        if (!eO) return toast.warn("Nothing to be saved...");

        // if (eO.phone !== originalOrder.patient.phone) {
        //     Sentry.captureMessage(
        //         `Phone number updated from ${originalOrder.patient.phone} to ${eO.phone}`,
        //         {
        //             fingerprint: ['phoneNumberChange']
        //         }
        //     );
        // }

        // For understanding the MBI Format: https://www.cms.gov/medicare/new-medicare-card/understanding-the-mbi.pdf
        if (eO.mbi) {
            // Check if the MBI is exactly 11 characters
            if (eO.mbi.length !== 11) {
                return toast.error("MBI must be 11 characters.") + this.setState({ saveProcessStarted: false });
            }

            // Check if the MBI contains any of the forbidden letters
            if (/S|L|O|I|B|Z/.test(eO.mbi.toUpperCase())) {
                return toast.error("MBI can't contain any of the following letters: S, L, O, I, B, Z.") + this.setState({ saveProcessStarted: false });
            }

            // Check MBI format
            if (!/^[1-9][A-z][\dA-z]\d[A-z][\dA-z]\d[A-z][A-z]\d\d$/.test(eO.mbi)) {
                return toast.error(`Invalid MBI format.`) + this.setState({ saveProcessStarted: false });
            }
        }


        if ((eO.status === 1 && !eO.orderStatusReasonId && eO.orderStatusReasonId === null) ||
            (eO.status === 3 && !eO.orderStatusReasonId && eO.orderStatusReasonId === null) ||
            (eO.status === 2 && !eO.orderStatusReasonId && eO.orderStatusReasonId === null)) {
            return toast.error("Order not saved. You must select a status reason.") + this.setState({ saveProcessStarted: false });
        }

        if (this.state.approveWithoutAuth) {
            OrderService.createPreAuth(eO.id)
                .then((res) => {
                    // console.log(res)
                })
                .catch(err => {
                    console.log(err);
                });
        }

        // In case the approvals array is empty put in try/catch so I dont error out trying to access ABN information...JK
        // If the statement is false then I don't have to worry about it anyway...JK
        try {
            if (
                eO.orderActivityApprovals[0].approvals.abnRequired === true &&
                !eO.abnProductOther
            ) {
                eO.orderActivityApprovals[0].approvals.abnRequired = false;
            }

            if (eO.orderActivityApprovals[0].approvals.deliverByDate) {
                eO.orderActivityApprovals[0].approvals.deliverByDate =
                    GlobalFunctions.getUTCMoment(eO.orderActivityApprovals[0].approvals.deliverByDate);
            }
            if (
                eO.abnProductOther &&
                (eO.abnAmount === "" ||
                    eO.abnAmount === null ||
                    eO.orderActivityApprovals[0].approvals.abnRequiredNote ===
                    "")
            ) {
                shouldSave = false;
                toast.error(
                    "One or more products have ABN required so ABN Amount and Reason are required"
                );
            }
        } catch (e) {
            if (eO.abnProductOther) {
                shouldSave = false;
                toast.error(
                    "One or more products have ABN required so ABN Amount and Reason are required"
                );
            }

            this.setState({
                saveProcessStarted: false,
            });
        }

        if (ePatient.phone) {
            if (!regex.test(ePatient.phone)) {
                toast.warning(
                    "Phone number is not valid.  Try re-entering and saving again."
                );
            }
        }


        //eO.type = "Order";

        // check the last note date.. this should fix any that are wrong
        if (notes && notes.length > 0) {
            let n = notes.sort((a, b) => {
                let da = new Date(a.createdAt).getTime(),
                    db = new Date(b.createdAt).getTime();

                return da > db ? -1 : 1;
            });
            eO.activity.lastNoteDate = n[0].createdAt;
        }

        if (shouldSave && this.validateSave(eO)) {
            //eO.lastUpdatedOn = new Date().toISOString();
            if (eO.orderStatusReasonId === 32 && originalOrder.orderStatusReasonId != 32) {
                eO.scheduledDeliveryDate = null;
                eO.setupDate = null;
                eO.needsRedelivery = true;
            }
            if (eO.orderStatusReasonId === 11 && originalOrder.status === 4) {
                eO.faceToFaceDate = null;
                eO.evaluationDate = null;
                eO.atpEvaluationDate = null;
            }

            eO.lastUpdatedBy = {
                id: currentUser.id,
                username: currentUser.username,
            };
            eO.lastUpdatedById = currentUser.id;

            if (st.order.status !== eO.status) {
                //If we have changed the status we need to update that field...BC
                // let date = new Date();
                eO.lastStatusChange = moment(new Date()).format(
                    "YYYY-MM-DDTHH:mm:ss"
                );
            }
            if (eO.orderStatusReason && eO.orderStatusReason.id === 8) {
                if (
                    st.order.orderStatusReason &&
                    st.order.orderStatusReason.id === 8
                ) {
                    // dont do anything is status isn't changing...JK
                } else {
                    eO.equipmentOrderedDate = new Date().toISOString();
                }
            }

            //need to make sure the dateOfBirth is in milliseconds otherwise it wont work..BC
            if (st.order.dateOfBirth !== eO.dateOfBirth) {
                eO.dateOfBirth = moment(eO.dateOfBirth).format("MM/DD/YYYY");
                eO.patient.dateOfBirth = moment(eO.dateOfBirth).format(
                    "MM/DD/YYYY"
                );
            }

            if (
                eO.orderActivityApprovals[0] != null &&
                eO.orderActivityApprovals[0].approvals &&
                eO.orderActivityApprovals[0].approvals.hasPrescription !== true
            ) {
                eO.orderActivityApprovals[0].approvals.stateSalesTax =
                    parseFloat(await this.calculateSalesTax(eO));
            }
            //if (eO.currentlyBeingWorkedById === currentUser.id) {
            //    eO.currentlyBeingWorkedById = null
            //}

            // wait for purchase queue to save so we can update the products with the newly created purchaseQueueId...JK
            this.updateOrderProperties(eO, ePatient);
            this.generateScheduledNote(originalOrder, eO);
            this.determineUpdateFollowUpDate(originalOrder, eO);
            this.saveOrderCall(eO);
            this.determineStatusTrigger(eO);
        } else {
            this.setState({
                saveProcessStarted: false,
            });
        }
    }

    savePurchaseQueue(item) {
        const { currentUser } = this.context;
        let st = this.state,
            eOrder = st.eOrder,
            products = st.orderProducts,
            nPurchaseQueues = [];

        if (item) {
            item.forEach((f) => {
                nPurchaseQueues.push({
                    orderId: eOrder.id,
                    requestedOn: new Date().toISOString(),
                    requestedBy: {
                        id: currentUser.id,
                        name: currentUser.username,
                    },
                    requestedById: currentUser.id,
                    quoteId: f.fileId,
                    quantity: 1,
                    statusChangedOn: new Date().toISOString(),
                    statusChangedBy: {
                        id: currentUser.id,
                        name: currentUser.username,
                    },
                    statusChangedById: currentUser.id,
                    status: "PENDING",
                    vendor: { name: f.vendor.label, id: f.vendor.value },
                    vendorId: f.vendor.value,
                });
            });
        } else {
            let pqArr = st.purchaseQueueArr;

            pqArr.map((pq) => {
                let currP = products.find((p) => {
                    return p.id === pq;
                }),
                    currPst = currP.products.productSubType;

                return nPurchaseQueues.push({
                    itemId: currPst.itemId || null,
                    orderId: eOrder.id,
                    quantity: currP.products.qty,
                    requestedOn: new Date().toUTCString(),
                    productSubType: { id: currPst.id, name: currPst.name },
                    requestedBy: {
                        id: currentUser.id,
                        name: currentUser.username,
                    },
                    vendor: currPst.vendor
                        ? { id: currPst.vendor.id, name: currPst.vendor.name }
                        : null,
                    quoteId: currP.products.quoteId,
                    productId: currP.products.id,
                    statusChangedOn: new Date().toUTCString(),
                    statusChangedBy: {
                        id: currentUser.id,
                        name: currentUser.username,
                    },
                    status: "PENDING",
                });
            });
        }

        return PurchaseQueueService.createPurchaseQueues(nPurchaseQueues)
            .then((res) => {
                toast.success("Product(s) ordered!");

                this.setState({
                    purchaseQueueArr: res || [],
                    pqModal: false,
                });
                this.getOrderProducts(this.state.id);
                //this.retrievePurchaseQueues(eOrder.id);
                eOrder.equipmentOrderedDate = new Date().toISOString();
                return eOrder;
            })
            .catch((err) => {
                toast.error("Oh no! An error occurred while saving.");
                console.log(err);
                return eOrder;
            });
    }

    saveOrderCall(order) {
        this.setState({
            saveProcessStarted: true,
        });
        let prods = this.state.updateProducts;

        if (prods && prods.length > 0 && this.state.updatingProducts === false) {
            this.setState({
                updatingProducts: true,
            });
            var ps = prods.map(x => x.product);
            // console.log(ps)
            OrderService.updateOrderProducts(order.id, ps)
                .then(res => {
                    if (res) {
                        this.setState({
                            updateProducts: [],
                            orderProducts: [],
                            updatingProducts: false,
                        }, () => this.getOrderProducts(order.id));
                    }
                    else {
                        toast.warn("There was an issue updating the products.");
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        updatingProducts: false,
                    });
                });
        }

        if (order.status !== this.state.order.status &&
            order.orderStatusReasonId !== this.state.order.orderStatusReasonId &&
            order.currentlyBeingWorkedById === this.state.order.currentlyBeingWorkedById) {
            order.currentlyBeingWorkedById = null;
        }

        //return OrderService.updateOrder(order)
        return OrderService.updateFlatOrder(order)
            .then((data) => {
                if (data.id !== order.id) {
                    toast.warn("Order was recently updated. Please refresh the page and resubmit your changes.");
                    this.setState({
                        disableOrderSave: true,
                        saveProcessStarted: false,
                    });
                    return false;
                }
                else {
                    toast.success("Saved Successfully!");

                    this.setState({
                        order: data,
                        eOrder: JSON.parse(JSON.stringify(data)),
                        saveProcessStarted: false,
                        dialogUpdateBrightreeExternalIdOpen: false,
                    });
                    return true;
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Oh no! An error occurred while saving.");
                this.setState({
                    saveProcessStarted: false,
                });
                return false;
            });
    }

    validateAndUpdateReceiveProduct(index, product) {
        let errors = [];

        let uProds = this.state.updateProducts;

        if (!this.state.selectedBaseUnit) {
            errors.push("Please select a base unit");
        }

        if (!this.state.baseUnitVendor) {
            errors.push("Please select a vendor");
        }

        if (!this.state.uSerial || this.state.uSerial.trim() === "") {
            errors.push("Please add a serial number");
        }

        if (errors.length > 0) {
            errors.map((e) => {
                return toast.error(e);
            });
            return false;
        } else {

            product.serial = this.state.uSerial;
            product.baseUnitId = this.state.uBaseUnitId;

            if (uProds && uProds.length > 0 && uProds.filter(x => x.index === index).length > 0)
                uProds.filter(x => x.index === index)[0].product = product;
            else
                uProds.push({ index: index, product: product });

            var ps = uProds.map(x => x.product);

            OrderService.updateOrderProducts(this.state.id, ps)
                .then(res => {
                    if (res) {
                        this.setState({
                            updateProducts: [],
                            orderProducts: [],
                            updatingProducts: false,
                        }, () => this.getOrderProducts(this.state.id));
                        toast.success("Saved Successfully!");
                    }
                    else {
                        toast.warn("There was an issue updating the products.");
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        updatingProducts: false,
                    });
                });

            this.setState({
                setProductDialogOpen: false,
            });
        }
    }

    validateSave(order) {
        let errors = [];
        const regex = /(\W(?:\s))/g;
        if (order.orderStatusReasonId === null) {
            errors.push("Order must have a status reason.");
        }

        // if(order.isComplex){
        //     if(!order.atp){
        //         errors.push("Please select an ATP");
        //     }
        // }

        if (!order.patient.firstName) {
            errors.push("Please fill out patient's first name");
        }
        if (!order.patient.lastName) {
            errors.push("Please fill out patient's last name");
        }

        if (regex.test(order.patient.firstName)) {
            errors.push("Remove punctuation and numbers from the first name");
        }

        if (regex.test(order.patient.lastName)) {
            errors.push("Remove punctuation and numbers from the last name");
        }

        if ((!this.state.orderProducts || this.state.orderProducts.length === 0) && this.state.updateProducts.length === 0) {
            errors.push("Order must have at least one product");
        }
        if (
            order.status === 2 &&
            (!order.patient.address ||
                !order.patient.city ||
                !order.patient.state ||
                !order.patient.zipCode)
        ) {
            errors.push(
                "Order must have an address for status to be set to Ready to Deliver"
            );
        }
        if (order.status === 3 && !order.setupDate) {
            errors.push("Must set a setup date to change status to Setup");
        }
        //if (
        //    order.orderStatusReasonId === null
        //) {
        //    errors.push("Status reason is required.");
        //}
        if (order.hasInfectiousDisease && !order.diseaseNotes) {
            errors.push(
                "Must fill out disease note if infections disease if checked"
            );
        }
        if (
            order.status === 1 &&
            this.state.order.status === 0 &&
            order.activity.account.name ===
            "Intake - " + order.activity.owner.name
        ) {
            errors.push("Account must be changed from intake account");
        }

        // if (!order.patient.latitude && !order.patient.longitude) {
        //     toast.warn('The address is not valid for this patient.');
        // }

        if (errors.length > 0) {
            errors.map((e) => {
                return toast.error(e);
            });
            return false;
        } else {
            return true;
        }
    }

    triggerSerialsPopup() {
        const { productTypes } = this.context;
        let prods = this.state.orderProducts;
        if (
            prods &&
            prods.length > 0 &&
            productTypes &&
            productTypes.length > 0
        ) {
            let serials = 0;
            prods.forEach((prod) => {
                if (
                    !prod.serialNumber &&
                    productTypes.find(
                        (typ) => typ.id === prod.productTypeId
                    ).requiresSerialNumber
                ) {
                    serials++;
                }
            });
            if (serials > 0) {
                toast.warn(
                    "There are " +
                    serials +
                    " order(s) included that require a serial number."
                );
            }
        }
    }

    //endregion

    toggle = (tabset, tab) => {
        if (tabset === "1") {
            if (this.state.TabsOneActiveItem !== tab) {
                this.setState({
                    TabsOneActiveItem: tab,
                });
            }
        } else {
            if (this.state.TabsTwoActiveItem !== tab) {
                this.setState({
                    TabsTwoActiveItem: tab,
                });
            }
        }
    };

    handleChange = (property, event) => {
        //update the property that the user has edited in the order...BC

        const nOrder = this.state.eOrder;
        if (property === "status") {
            nOrder.orderStatusReason = null;
            nOrder.orderStatusReasonId = null;
        }

        nOrder[property] = event.target.value;

        this.setState({ eOrder: nOrder });
    };

    getPointsFromInsurance(change, productAdded, fromInsurance) {
        const { productTypes, productSubTypes, productOverrides } = this.context;

        if (productTypes.length === 0) {
            return productAdded.products;
        }
        let o = this.state.eOrder,
            insuranceID = o.insuranceType
                ? o.insuranceType.id
                : o.insuranceTypeId;

        let correctProduct = {},
            insuranceMatrix = [],
            points = -1;

        productAdded.forEach((p, index) => {
            let productAddedID = p.productTypeId,
                found = false;

            //if (!productAddedID) {
            //    return;
            //}

            for (let pp of productTypes) {
                if (pp.id === productAddedID) {
                    correctProduct = pp;
                    break;
                }
            }

            if (p.points === -1 || p.points === 1000 || fromInsurance) {
                //no points set or change is triggered from insurance change...BC
                var pst = p.productSubTypeId ? productSubTypes.filter(x => x.id === p.productSubTypeId)[0] : null;
                if (pst && pst.overridePoints) {
                    let f = productOverrides.filter((pro) => {
                        return (
                            pro.insuranceType.id === o.insuranceType.id &&
                            pro.productSubType.id === p.productSubTypeId
                        );
                    });
                    if (f.length > 0) {
                        points = f[0].points;
                        found = true;
                    }
                }
                if (correctProduct && found === false) {
                    insuranceMatrix = correctProduct.insuranceMatrixEntry;
                    for (let i of insuranceMatrix) {
                        if (i.insuranceTypeId === insuranceID) {
                            points = i.points;
                            break;
                        }
                    }
                }
                p.points = points;
            }
        });

        if (change === "add") {
            delete productAdded[0].points;
            return points;
        } else if (change === "edit") {
            return productAdded;
        }
    }

    addNewProduct = (productAdded) => {
        const { companyName } = this.context;

        let points = -1,
            prods = this.state.updateProducts,
            currProds = this.state.orderProducts,
            np = {
                abnRequired: false,
                approved: false,
                hcpcs: productAdded.hcpcs || "",
                //points: -1,
                //productSubType: {
                //    id: productAdded.id,
                //    name: productAdded.name,
                //    hcpcs: productAdded.hcpcs,
                //    overridePoints: productAdded.overridePoints,
                //},
                productSubTypeId: productAdded.id,
                //productType: {
                //    id: productAdded.productType.id,
                //    name: productAdded.productType.name,
                //},
                productTypeId: productAdded.productType.id,
                qty: 1,
                serial: "",
                amount: 0,
                isRental: false,
                orderActivityProducts: { orderActivityId: this.state.order.id },
                // updatedOn: new Date(),
                // updatedBy: this.setUserRef()
            };

        if (companyName !== "rehabmedical" && this.state.pricesheets && this.state.pricesheets.length > 0) {
            var ps = this.state.pricesheets.filter(x => x.productTypeId === productAdded.productTypeId && x.productSubTypeId === productAdded.id);
            if (ps && ps.length > 0) {
                np.amount = ps[0].rate;
            }
        }

        np.points = points;
        let inst = this.state.eOrder.insuranceType ? this.state.eOrder.insuranceType.name : "None";
        let shouldCalculateCost = (companyName !== "rehabmedical" &&
            ["Long Term Care", "Dealer", "Hospital"].includes(inst));
        if (shouldCalculateCost) {
            np.points = parseFloat((np.amount / 5000).toFixed(2));
        }

        // get any product specific overrides
        prodcutMatrixEntryService.getProductMatrixEntryBySubType(productAdded.id)
            .then(res => {
                let entries = res.filter(x => x.insuranceTypeId === this.state.eOrder.insuranceTypeId);
                if (entries && entries.length > 0) {
                    np.points = entries[0].points;
                }
                else {
                    np.points = points;
                }

                currProds.push(np);
                prods.push({ index: currProds.length - 1, product: np });

                this.setState({
                    updateProducts: prods,
                    orderProducts: currProds,
                }, () => this.updateTableRows());
            })
            .catch(err => console.log(err));
    };

    toggleTimelineModalPopup() {
        let newState = !this.state.timelineModalOpen,
            order = this.state.order;

        //let records = [];

        //records.push({
        //    status: "New",
        //    reason: "",
        //    updatedOn: new Date(),
        //});

        //records.push({
        //    status: "In Process",
        //    reason: "Verification",
        //    updatedOn: new Date(),
        //});

        //records.push({
        //    status: "Ready To Deliver",
        //    reason: "Scheduled",
        //    updatedOn: new Date(),
        //});

        //We don't want to call the endpoint if the modal is being closed...BC
        if (newState === false) {
            this.setState({
                timelineModalOpen: newState,
            });
        } else {
            OrderService.getOrderTimeline(order)
                .then((records) => {
                    this.setState({
                        timelineModalOpen: newState,
                        timelineModalOrder: order,
                        timelineModalEntries: records,
                    });
                }
                ).catch((err) => console.log(err));
        }
    }

    renderLoadingSpinner() {
        return (
            <MDBContainer>
                <div className={"initialLoad"}>
                    <MDBRow>
                        <MDBCol style={{ textAlign: "center" }} size="12">
                            <Spinner multicolor />
                        </MDBCol>

                        <MDBCol
                            style={{ textAlign: "center", color: "whitesmoke" }}
                            size="12"
                        >
                            <h4>Loading Order...</h4>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer>
        );
    }

    renderNotFound() {
        return (
            <MDBContainer>
                <div className={"initialLoad"}>
                    <MDBRow>
                        <MDBCol
                            style={{ textAlign: "center", color: "whitesmoke" }}
                            size="12"
                        >
                            <h2>Order Not Found!</h2>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer>
        );
    }

    closeHelpModal() {
        this.setState({
            helpModalOpen: false,
        });
    }

    setExistingEmpty() {
        this.setState({
            existingMeeting: null,
        });
    }

    renderCurrentStatus() {
        let eOrder = this.state.eOrder;
        if (eOrder.status) {
            if (eOrder.status === 2) {
                return "Ready To Deliver";
            } else {
                return this.formatStatus(eOrder.status);
            }
        }
        return "";
    }

    toggleApprovalModalPopup = () => {
        let currentState = this.state.approvalModalOpen;
        this.setState({
            approvalModalOpen: !currentState,
            approveAgreement: false,
            approveWithoutAuth: false,
            approveAuthNotRequired: false,
        });
    };

    toggleBTIDModalPopup = () => {
        const { companyName } = this.context;
        if (companyName !== "corkmedical") {
            let currentState = this.state.BTIDModal;
            if (currentState) {
                this.setState({
                    idsLoadedCount: 0,
                    BTIDModal: !currentState,
                    idsLoaded: false,
                    idNames: [],
                    btitems: null,
                });
                return;
            } else {
                let items = this.state.orderProducts;
                this.setState({ btitems: JSON.parse(JSON.stringify(items)) });
                this.getIDs();
                this.setState({ BTIDModal: !currentState });
            }
        }
    };

    toggleApprovalErrorsModal = () => {
        this.setState({
            approvalErrorsModal: !this.state.approvalErrorsModal,
        });
    };

    toggleFollowupNoteModal = () => {
        let currentState = this.state.followupModalOpen;
        this.setState({
            followupModalOpen: !this.state.followupModalOpen,
        });
        if (currentState === false) {
            this.setState({
                selectedVerificationNoteRadio: null,
            });
        }
    };

    canApproveOrder = () => {
        let eOrder = this.state.eOrder,
            patient = eOrder.patient,
            approval = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null,
            errors = [];

        function validatePhoneNumber(phoneNumber) {
            // Remove all non-numeric characters
            const cleaned = phoneNumber.replace(/\D/g, '');

            // Check if the cleaned string has exactly 10 digits
            if (cleaned.length === 10) {
                return true;
            } else {
                return false;
            }
        }

        let approved = this.state.orderProducts.some((prod) => {
            return prod.approved === true;
        });

        if (approved === false) {
            errors.push("At least one product needs to be approved");
        }

        if (!patient.dateOfBirth) {
            errors.push("Add Date of Birth");
        }
        if (!patient.phone) {
            errors.push("Add Phone Number");
        }
        if (!validatePhoneNumber(patient.phone)) {
            errors.push(
                "Phone number is invalid.  Try re-entering phone Number"
            );
        }
        if (!patient.address) {
            errors.push("Add Address");
        }
        if (!patient.city) {
            errors.push("Add City");
        }
        if (!patient.state) {
            errors.push("Add State");
        }
        if (!patient.zipCode) {
            errors.push("Add Zip Code");
        }
        if (eOrder.status === 2) {
            errors.push("Order Cannot be in Ready to Deliver");
        }
        if (eOrder.status === 3) {
            errors.push("Order Cannot be in Setup");
        }
        if (eOrder.status === 4) {
            errors.push("Order Cannot be Cancelled");
        }
        if (!eOrder.insuranceSubType) {
            errors.push("Please add an Insurance type");
        }

        if (approval && (
            !eOrder.billingLocationId ||
            !approval.commissionTierTermId ||
            !approval.placeOfServiceTermId ||
            !approval.authNumber ||
            approval.financialHardship === null ||
            approval.activeRentalSameOrSimilar === null ||
            approval.rental === null ||
            approval.unapprovedUpgrade === null)
        ) {
            errors.push("Approval Information must be completed (all fields required)")
        }

        if (errors.length > 0) {
            this.setState({
                approvalErrors: errors,
            });
            this.toggleApprovalErrorsModal();
        } else {
            this.toggleApprovalModalPopup();
        }
    };

    async calculateSalesTax(eOrder) {
        if (
            eOrder.insuranceType &&
            (eOrder.insuranceType.name === "Cash" ||
                eOrder.insuranceType.name === "Dealer")
        ) {
            const { stateSalesTax } = this.context;
            if (!eOrder.patient.state) {
                return 0;
            }
            let cs = eOrder.patient.state.toLowerCase();

            let tr = stateSalesTax.find((v) => {
                return (
                    v.abbr.toLowerCase() === cs || v.name.toLowerCase() === cs
                );
            });

            if (tr) {
                let t = 0;
                this.state.orderProducts.forEach((p) => {
                    let c = parseFloat(p.amount);
                    if (p.approved === true && c > 0) {
                        t += c;
                    }
                });

                return ((t / 100) * tr.salesTax).toFixed(2);
            }
        }
        return 0;
    }

    setAndUpdate(prop, val) {
        let eOrder = this.state.eOrder;

        if (eOrder.hasOwnProperty(prop)) {
            eOrder[prop] = val;
        }

        this.setState({ eOrder: eOrder });
        this.updateParentObject(eOrder);
    }

    updateReason(v) {
        const { reasons } = this.context;

        if (v) {
            let r = reasons.find((o) => {
                return v === o.id;
            });

            if (r) {
                let q = {
                    id: r.id,
                    description: r.description,
                };
                this.setAndUpdate("orderStatusReason", q);
                this.setAndUpdate("orderStatusReasonId", q.id);
            }
        }
    }

    async generateApprovalNote() {
        const { currentUser, productTypes, productSubTypes } = this.context;

        let st = this.state,
            noteText = "",
            list = st.orderProducts.filter((p) => {
                return p.approved;
            }),
            cb = { id: currentUser.id, name: currentUser.username },
            currentNotes = st.notes,
            eOrder = st.eOrder,
            totalPoints = 0;

        let appr = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null,
            preAuth = appr.authNumber ? appr.authNumber.trim() : null;

        if (this.state.approveWithoutAuth) {
            noteText =
                currentUser.firstname +
                " " +
                currentUser.lastname +
                " approved order " +
                this.state.order.id +
                " and sent to Pre-Auth follow up for authorization on " +
                moment().format("MMMM Do YYYY, h:mm a") +
                " for the following products : ";
        } else if (this.state.approveAuthNotRequired) {
            noteText =
                currentUser.firstname +
                " " +
                currentUser.lastname +
                " approved order " +
                this.state.order.id +
                " (Pre-Auth not required) on " +
                moment().format("MMMM Do YYYY, h:mm a") +
                " for the following products : ";
        } else {
            noteText =
                currentUser.firstname +
                " " +
                currentUser.lastname +
                " approved order " +
                this.state.order.id +
                " with Auth Number " +
                preAuth +
                " on " +
                moment().format("MMMM Do YYYY, h:mm a") +
                " for the following products : ";
        }


        list.forEach((product) => {
            var pt = product.productTypeId ? productTypes.filter(x => x.id === product.productTypeId)[0].name || "" : "";
            var pst = product.productSubTypeId ? productSubTypes.filter(x => x.id === product.productSubTypeId)[0].name || "" : "";
            totalPoints += parseFloat(product.points);
            noteText += product.qty + " " + pt + " " + pst + " | ";
        });

        let note = {
            type: "ActivityNote",
            text: noteText,
            createdAt: GlobalFunctions.getUTCMoment(),
            account: eOrder.activity.account,
            accountId: eOrder.activity.accountId,
            task: false,
            completed: true,
            createdBy: cb,
            createdById: cb.id,
            patientName: eOrder.patientName,
            activityId: eOrder.id,
        };

        NoteService.createActivityNote(eOrder.id, note)
            .then((res) => {
                if (res.createdAt.indexOf("Z") > 0) {
                    res.createdAt = res.createdAt.split("Z")[0];
                }
                currentNotes.unshift(res);
            })
            .catch((err) => {
                console.log(err);
            });

        if (eOrder.approvalDate === null) {
            eOrder.approvalDate = new Date();
        }

        eOrder.points = totalPoints;
        eOrder.status = 2; //"ReadyToDeliver";
        this.updateReason(85); //"Approved - Ready to Work"
        this.saveOrder(eOrder);
        //this.createPatientCommunication(eOrder.patientId, eOrder.id, "SMS");
        this.toggleApprovalModalPopup();
    }

    createPatientCommunication(patientId, orderId, commType) {
        const { companyName } = this.context;
        if (
            ["local", "uat", "rehabmedical"].includes(companyName) &&
            this.state.order !== null &&
            [2, 26].includes(this.state.order.serviceLocationId)
        ) {
            var ds = new Date();
            var day = ds.getDate(),
                month = ds.getMonth() + 1,
                yr = ds.getFullYear();

            var dateStamp =
                (month < 9 ? "0" : "") +
                month.toString() +
                "/" +
                (day < 9 ? "0" : "") +
                day.toString() +
                "/" +
                yr;

            var f = {
                orderId: orderId,
                patientId: patientId,
                sendDate: dateStamp,
                type: commType,
                name: "Patient Registration",
            };
            PatientCommunicationService.createPatientCommunication(f)
                .then((res) => {
                    if (res) {
                        //toast.success("The link has been created for the patient.");
                        console.log(
                            "The link has been created for the patient."
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    renderHomeAssessmentModal() {
        if (!this.state.homeAssessmentModalOpen) { return; }
        return (
            <Modal
                size={"lg"}
                isOpen={this.state.homeAssessmentModalOpen}
                toggle={() => { }}
            >
                <HomeAssessmentModal
                    setReload={this.setReload.bind(this)}
                    homeAssessmentFromWeb={this.state.homeAssessmentFromWeb}
                    order={this.state.eOrder}
                    toggleHomeAssessmentModal={this.toggleHomeAssessmentModal.bind(
                        this
                    )}
                />
            </Modal>
        );
    }

    renderApprovalErrorsModal() {
        let errors = this.state.approvalErrors;
        return (
            <Modal
                isOpen={this.state.approvalErrorsModal}
                toggle={this.toggleApprovalErrorsModal}
            >
                <ModalHeader toggle={this.toggleApprovalErrorsModal}>
                    Approval Errors
                </ModalHeader>
                <ModalBody>
                    <MDBListGroup className="text-left">
                        {errors.map((e, index) => {
                            return (
                                <MDBListGroupItem
                                    key={"errorList" + index}
                                    className="red-gradient white-text"
                                >
                                    {e}
                                </MDBListGroupItem>
                            );
                        })}
                    </MDBListGroup>
                </ModalBody>
            </Modal>
        );
    }

    renderApprovalModal() {
        const { productTypes, productSubTypes } = this.context;
        let st = this.state,
            eOrder = st.eOrder,
            pat = eOrder.patient,
            appr = eOrder.orderActivityApprovals[0] ? eOrder.orderActivityApprovals[0].approvals : null,
            preAuth = appr.authNumber ? appr.authNumber.trim() : null;

        if (!pat) {
            return;
        }
        return (
            <Modal
                centered
                isOpen={st.approvalModalOpen}
                toggle={() => this.toggleApprovalModalPopup()}
            >
                <ModalBody>
                    <CardHeader
                        style={{ marginBottom: "1rem" }}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>
                            Approve Order :{" "}
                            {eOrder.patient.firstName +
                                " " +
                                eOrder.patient.lastName}
                        </h3>
                    </CardHeader>
                    {appr && preAuth ? (<p style={{ marginLeft: '3px' }}>Pre-Auth Number: <b>{preAuth}</b></p>) : null}
                    <Table
                        responsive
                        fixed
                        scrollY
                        striped
                        bordered
                        autoWidth
                        maxHeight="400px"
                    >
                        <TableHead>
                            <tr>
                                <th>Category</th>
                                <th>Product</th>
                                <th>Quantity</th>
                            </tr>
                        </TableHead>
                        <TableBody>
                            {this.state.orderProducts.map(
                                (product, index) => {
                                    if (product.approved === true) {
                                        var pt = product.productTypeId && productTypes.filter(x => x.id === product.productTypeId).length > 0 ? productTypes.filter(x => x.id === product.productTypeId)[0] : null;
                                        var pst = product.productSubTypeId && productSubTypes.filter(x => x.id === product.productSubTypeId).length > 0 ? productSubTypes.filter(x => x.id === product.productSubTypeId)[0] : null;

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {pt && pt.name ? pt.name : ""}
                                                </td>
                                                <td>
                                                    {pst && pst.name ? pst.name : ""}
                                                </td>
                                                <td>{product.qty}</td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                }
                            )}
                        </TableBody>
                    </Table>
                    {appr && preAuth ? (<div align="left">
                        <Input
                            key={eOrder.id}
                            onChange={this.checkBoxApprovedChange.bind(this)}
                            value={this.state.approveAgreement}
                            label={`I approve this order with Auth Number: ${preAuth}`}
                            type="checkbox"
                            id="approveOrder"
                        />
                    </div>) : null}

                    {appr && !preAuth ? (<div align="left">
                        <Input
                            disabled={this.state.approveWithoutAuth}
                            key={eOrder.id}
                            onChange={this.checkBoxApprovedAuthNotRequired.bind(this)}
                            value={this.state.approveAuthNotRequired}
                            label="I approve this order and Pre-Auth is Not Required"
                            type="checkbox"
                            id="approveAuthNotRequired"
                        />
                        <Input
                            disabled={this.state.approveAuthNotRequired}
                            key={eOrder.id}
                            onChange={this.checkBoxApprovedWithoutAuth.bind(this)}
                            value={this.state.approveWithoutAuth}
                            label="I approve this order and send to Pre-Auth follow up for authorization"
                            type="checkbox"
                            id="approveOrderWithoutAuth"
                        />
                    </div>) : null}
                </ModalBody>
                <ModalFooter>
                    <MDBBtn
                        color="secondary"
                        onClick={() => this.toggleApprovalModalPopup()}
                    >
                        Close
                    </MDBBtn>
                    <MDBBtn
                        disabled={this.state.approveAgreement || this.state.approveWithoutAuth || this.state.approveAuthNotRequired ? false : true}
                        color="primary"
                        onClick={() => this.generateApprovalNote()}
                    >
                        Approve
                    </MDBBtn>
                </ModalFooter>
            </Modal>
        );
    }

    timeSelectPlaceholder(date) {
        if (date) {
            return new Date(date).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
            });
        } else {
            return "Select a Time";
        }
    }

    renderTimeOptions() {
        let h = [],
            ampm = [],
            hour = 0,
            times = [],
            minutes = ["00", "15", "30", "45"];
        for (let i = 6; i < 22; i++) {
            hour = i % 12 === 0 ? 12 : i % 12;
            h.push(hour.toString());
            i % 24 < 12 ? ampm.push("AM") : ampm.push("PM");
        }
        h.map((nh, k) => {
            return minutes.map((m) => {
                return times.push({
                    label: nh + ":" + m + " " + ampm[k],
                    value: [nh, m],
                });
            });
        });
        return times;
    }

    handleOpenOrderTimeChange(e) {
        let eo = this.state.eOrder;

        if (eo.scheduledDeliveryDate) {
            let d = new Date(eo.scheduledDeliveryDate);

            d.setHours(e.value[0]);
            d.setMinutes(e.value[1]);

            eo.scheduledDeliveryDate = d;
        }

        this.setState({
            eOrder: eo,
            openOrderTime: e.value,
        });
    }

    //endregion

    //region clinic notes
    addNewClinicNote() {
        //get the current user context that can be passed into the note DTO...BC
        const { currentUser } = this.context;

        let cb = { id: currentUser.id, name: currentUser.username },
            currentNotes = this.state.clinicNotes,
            therapist = this.state.eOrder.therapist;

        let note = {
            createdAt: GlobalFunctions.getUTCMoment(),
            createdBy: cb,
            createdById: cb.id,
            assignedTo: therapist,
            assignedToId: therapist.id,
            completed: this.state.completed,
            activityId: this.state.order.id,
            patientName: this.state.order.patientName,
        };

        let nText = document.getElementById("newClinicNoteText").value;
        note.text = nText;

        return NoteService.createTherapistNote(note)
            .then((res) => {
                this.toggleNoteModalPopup();
                currentNotes.unshift(note);
                this.setState({ clinicNotes: currentNotes });
            })
            .catch((err) => {
                //Maybe send an error to the user?...BC
            });
    }

    updateClinicNote(note) {
        let clinicNotes = this.state.clinicNotes;
        note.completed = true;

        return NoteService.updateTherapistNote(note)
            .then((res) => {
                this.setState({ clinicNotes: clinicNotes });
                //success, don't need to do anything...BC
            })
            .catch((err) => {
                //maybe throw an error if it fails...BC
            });
    }

    renderSeatingClinicTabLink() {
        if (this.state.eOrder.seatingClinicLocation != null) {
            return (
                <MDBNavItem>
                    <MDBNavLink
                        link
                        to="#"
                        role="tab"
                        className={classnames({
                            active: this.state.TabsOneActiveItem === "7",
                        })}
                        onClick={() => {
                            this.toggle("1", "7");
                        }}
                    >
                        Seating Clinic Notes
                    </MDBNavLink>
                </MDBNavItem>
            );
        }
        return <div />;
    }

    renderSeatingClinicTabPane(theme) {
        if (this.state.eOrder.seatingClinicLocation != null) {
            return (
                <MDBTabPane
                    tabId="7"
                    role="tabpanel"
                    style={{ backgroundColor: theme.cardBackground }}
                >
                    {this.renderSeatingClinicNotesTab(theme)}
                </MDBTabPane>
            );
        }
        return <div></div>;
    }

    renderSeatingClinicNotesTab(theme) {
        let notes = this.state.clinicNotes;

        return (
            <MDBRow>
                <MDBCol size="12">
                    {this.renderClinicNoteModal(theme)}

                    <div className={"pvNotesGroupOuterContainer"}>
                        <div className={"pvNotesGroupInnerContainer"}>
                            {notes.map((note, idx) => {
                                return this.generateClinicNote(
                                    note,
                                    idx,
                                    theme
                                );
                            })}
                        </div>
                    </div>
                    <hr />
                </MDBCol>
                <MDBCol style={{ textAlign: "right" }}>
                    <MDBBtn
                        color={"teal darken-2"}
                        disabled={!this.state.eOrder.therapist}
                        rounded
                        size="md"
                        onClick={() => this.toggleNoteModalPopup()}
                    >
                        <MDBIcon icon="plus" />
                        &nbsp;&nbsp; Add Clinic Note
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
        );
    }

    generateClinicNote(note, index) {
        let t = this;

        function renderCompleteButton(note) {
            if (note.completed === false) {
                return (
                    <Button
                        floating
                        className={"pvButtonFloat"}
                        data-tip={"Complete Note"}
                        color={"red darken-2"}
                        onClick={() => t.updateClinicNote(note)}
                        size="sm"
                    >
                        <MDBIcon icon={"check-square"} />
                    </Button>
                );
            }
        }

        return (
            <div className={"pvNoteContainer"} key={index}>
                <Card>
                    <CardHeader color={"teal darken-2"}>
                        {note.createdBy.name} &nbsp;&nbsp;&nbsp;
                        <span style={{ float: "right" }}>
                            {GlobalFunctions.formatDateTime(note.createdAt)}
                        </span>
                    </CardHeader>
                    <CardBody className={"pvNoteText"}>
                        {note.text}
                        <br />
                        {renderCompleteButton(note)}
                    </CardBody>
                </Card>
                <br />
            </div>
        );
    }

    renderClinicNoteModal() {
        return (
            <MDBContainer>
                <MDBRow>
                    <Modal
                        centered
                        className="patientOrderView form-elegant"
                        isOpen={this.state.clinicNoteModalOpen}
                        toggle={() => this.toggleNoteModalPopup()}
                    >
                        <ModalBody
                            style={{ backgroundColor: "#FFFFFF" }}
                            className="mx-3"
                        >
                            <CardHeader className="blue-grey form-header text-center">
                                Add New Note
                            </CardHeader>
                            <form className=" mx-3 grey-text">
                                <textarea
                                    placeholder={"Add note text here"}
                                    id={"newClinicNoteText"}
                                    style={{ width: "100%", height: "200px" }}
                                ></textarea>
                                <br />
                                <Row>
                                    <Col size="6">
                                        <Input
                                            type="radio"
                                            gap
                                            label={"Note"}
                                            checked={this.state.taskCompleted}
                                            onClick={this.taskCompletedChange.bind(
                                                this,
                                                true
                                            )}
                                            id={"clinicNoteRadio"}
                                        />
                                    </Col>

                                    <Col size="6">
                                        <Input
                                            type="radio"
                                            gap
                                            label={"Task"}
                                            checked={!this.state.taskCompleted}
                                            onClick={this.taskCompletedChange.bind(
                                                this,
                                                false
                                            )}
                                            id={"clinicTaskRadio"}
                                        />
                                    </Col>
                                </Row>

                                <br />
                                <Button
                                    disabled={!this.state.eOrder.therapist}
                                    onClick={this.addNewClinicNote.bind(this)}
                                >
                                    Add Clinic Note
                                </Button>
                            </form>
                        </ModalBody>
                    </Modal>
                </MDBRow>
            </MDBContainer>
        );
    }

    //endregion

    noteChange = (property, event) => {
        this.setState({ [property]: event.target.value });
    };

    getCategoryList() {
        const { productTypes } = this.context;
        let a = [];
        productTypes.forEach((p) => {
            a.push({
                label: p.name,
                value: p,
            });
        });
        this.setState({
            productTypes: a,
        });
    }

    renderProductList() {
        const { productSubTypes } = this.context;
        let cc = this.state.chosenCategory;
        let a = [];

        if (productSubTypes && productSubTypes.length > 0) {
            productSubTypes.forEach((p) => {
                if (!cc || !cc.id || cc.id === p.productType.id) {
                    a.push({
                        label:
                            p.name + (p.hcpcs !== null ? " | " + p.hcpcs : ""),
                        value: p,
                    });
                }
            });
        }
        this.setState({
            productSubTypes: a,
            allProductSubTypes: productSubTypes,
        });
    }

    handleCategoryChange = (e) => {
        this.setState(
            {
                chosenCategory: e.value,
            },
            () => {
                this.renderProductList();
            }
        );
    };

    renderCategoryDropdown() {
        let statusSetup = this.state.order.status === 3;

        return (
            <div>
                <Select
                    isDisabled={statusSetup}
                    placeholder={"Category..."}
                    options={this.state.productTypes}
                    onChange={this.handleCategoryChange.bind(this)}
                />
            </div>
        );
    }

    handleProductSelectChange = (e) => {
        this.addNewProduct(e.value);
    };

    renderProductDropdown() {
        let statusSetup = this.state.order.status === 3;
        return (
            <div>
                <Select
                    isDisabled={statusSetup}
                    placeholder={"Product..."}
                    options={this.state.productSubTypes}
                    onChange={this.handleProductSelectChange.bind(this)}
                />
            </div>
        );
    }

    renderEquipmentCard(theme) {
        return (
            <div>
                <MDBRow>
                    <MDBCol size="12">
                        {this.renderTableToolbar()}
                        {this.renderProductTable()}
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

    viewABNClicked = () => {
        let o = this.state.eOrder;
        if (o.abnProductOther) {
            //if (o.orderActivityApprovals.length === 0) {
            //    o.orderActivityApprovals.push({
            //        approvals: {
            //            abnRequired: true,
            //            hasPrescription: false,
            //            abnRequiredNote: "",
            //            authNumber: null,
            //            coInsurance: null,
            //            deductible: null,
            //            deliverByDate: null,
            //            fieldNotes: null,
            //            financialHardship: null,
            //            hcpcs: null,
            //            inFacility: null,
            //            patientResponsibility: null,
            //            paymentOnDelivery: null,
            //            rental: null,
            //            sameOrSimilar: null,
            //        }
            //    });
            //}
            //else {
            //    o.orderActivityApprovals[0].approvals.abnRequired = true;
            //}
            this.setState({
                eOrder: o,
            });
            this.abnToggle();
        } else {
            toast.error("No products require ABN");
        }
    };

    abnToggle = () => {
        this.setState({
            abnModal: !this.state.abnModal,
        });
    };

    renderAbnModal() {
        let o = this.state.eOrder;

        return (
            <Modal isOpen={this.state.abnModal} toggle={() => this.abnToggle()}>
                <ModalHeader style={{ backgroundColor: "white" }}>
                    ABN
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    <div>
                        <MDBListGroup style={{ borderRadius: "5px" }}>
                            {this.renderABNProductList()}
                        </MDBListGroup>
                        <MDBInput
                            label="ABN Amount"
                            outline
                            value={
                                o.abnAmount === null
                                    ? ""
                                    : o.abnAmount.toString()
                            }
                            onChange={this.handleABNModalChange.bind(
                                this,
                                "abnAmount"
                            )}
                        />
                        <label className="grey-text">ABN Required Reason</label>
                        <textarea
                            id="abnreason"
                            className="form-control"
                            rows="3"
                            value={
                                o.orderActivityApprovals &&
                                    o.orderActivityApprovals.length > 0 &&
                                    o.orderActivityApprovals[0].approvals &&
                                    o.orderActivityApprovals[0].approvals
                                        .abnRequiredNote
                                    ? o.orderActivityApprovals[0].approvals
                                        .abnRequiredNote
                                    : ""
                            }
                            onChange={this.handleABNModalChange.bind(
                                this,
                                "abnRequiredNote"
                            )}
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    // ADD ABN Required reason *******************

    handleABNModalChange(property, e) {
        let o = this.state.eOrder;
        if (property === "abnAmount") {
            o[property] = parseFloat(e.target.value);
        } else {
            o.orderActivityApprovals[0].approvals[property] = e.target.value;
        }
        this.setState({
            eOrder: o,
        });
    }

    renderABNProductList() {
        if (this.state.abnModal) {
            let o = this.state.eOrder;
            let arr = o.abnProductOther.split(",");
            return arr.map((p) => {
                return (
                    <MDBListGroupItem className="winter-neva-gradient">
                        {p}
                    </MDBListGroupItem>
                );
            });
        }
    }

    updateTableRows(fromInsurance) {
        const { companyName, productTypes, productSubTypes, allBaseUnits, allBaseUnitTypes, allVendors } = this.context;
        let baseUnitType = null;
        let item = this.state.eOrder,
            arr = [],
            d = {};

        let products = this.state.orderProducts;

        if (item.insuranceType || item.insuranceTypeId) {
            //only get points from insurance if we have an insurance type...BC
            products = this.getPointsFromInsurance(
                "edit",
                products,
                fromInsurance
            );
        }

        let pc = this.state.primaryColumns.slice(0),
            sc = this.state.secondaryColumns.slice(0),
            inst = item.insuranceType ? item.insuranceType.name : "None";

        if (
            inst === "Cash" ||
            inst === "Dealer" ||
            (companyName === "corkmedical" &&
                ["Long Term Care", "Hospital"].includes(inst))
        ) {
            pc.push({
                label: "Cost",
                field: "cost",
            });
        }

        if (companyName === "corkmedical") {
            pc.push({
                label: "Rental",
                field: "isRental",
            });
        }

        if (item.orderType === "Service") {
            pc.push({
                label: "Warranty",
                field: "warranty",
            });
        }

        pc = pc.concat(sc);
        if (products) {
            products.map((product, index) => {
                var baseUnit = allBaseUnits.find((b) => b.id === product.baseUnitId);
                if (baseUnit && baseUnit !== null) {
                    baseUnitType = allBaseUnitTypes.find((t) => t.id === baseUnit.baseUnitTypeId);
                }
                var vendor = baseUnit ? allVendors.find((v) => v.id === baseUnit.vendorId) : null;
                var pt = product.productTypeId ? productTypes.filter(x => x.id === product.productTypeId)[0] : null;
                var pst = product.productSubTypeId && productSubTypes.filter(x => x.id === product.productSubTypeId).length > 0 ? productSubTypes.filter(x => x.id === product.productSubTypeId)[0] : null;

                let o = {
                    category: pt
                        ? this.renderFlatRow(product.baseUnitId && product.baseUnitId !== null && baseUnitType && baseUnitType !== null ? baseUnitType.name : pt.name, index)
                        : "",
                    productSub: this.renderFlatRow(
                        pst === null
                            ? "no product"
                            : product.baseUnitId && product.baseUnitId !== null && vendor && vendor !== null && baseUnit && baseUnit !== null ? vendor.name + " " + baseUnit.name : pst.name,
                        index
                    ),
                    hcpcs: this.renderFlatRow(
                        product.hcpcs
                            ? product.hcpcs
                            : (pst && pst.hcpcs ? pst.hcpcs : ""),
                        index
                    ),
                    points: this.renderRow(
                        "points",
                        product.points,
                        index,
                        product
                    ),
                    qty: this.renderRow("qty", product.qty, index, product),
                    serial: this.renderRow(
                        "serial",
                        product.serial,
                        index,
                        product
                    ),
                };

                let oTwo = {};
                if (companyName !== "corkmedical") {
                    oTwo = {
                        btID: this.renderRow(
                            "btID",
                            product.btitemId,
                            index,
                            product
                        ),
                        approved: this.renderRow(
                            "approved",
                            product.approved,
                            index,
                            product
                        ),
                        abnRequired: this.renderRow(
                            "abnRequired",
                            product.abnRequired,
                            index,
                            product
                        ),
                        remove: this.renderRemove(product, index),
                    };
                } else {
                    oTwo = {
                        approved: this.renderRow(
                            "approved",
                            product.approved,
                            index,
                            product
                        ),
                        abnRequired: this.renderRow(
                            "abnRequired",
                            product.abnRequired,
                            index,
                            product
                        ),
                        remove: this.renderRemove(product, index),
                    };
                }

                if (
                    inst === "Cash" ||
                    inst === "Dealer" ||
                    (companyName === "corkmedical" &&
                        ["Long Term Care", "Hospital"].includes(inst))
                ) {
                    o.amount = this.renderRow(
                        "amount",
                        product.amount,
                        index,
                        product
                    );
                }

                if (companyName === "corkmedical") {
                    o.isRental = this.renderRow(
                        "isRental",
                        product.isRental,
                        index,
                        product
                    );
                }

                if (item.orderType === "Service") {
                    o.warranty = this.renderRow(
                        "warranty",
                        product.warranty,
                        index,
                        product
                    );
                }

                let oThree = { ...o, ...oTwo };

                return arr.push(oThree);
            });
        }

        d.rows = arr;
        d.columns = pc;
        this.setState({
            data: d,
            productsLoaded: true,
        });
    }

    renderRemove(product, index) {
        return (
            <div>
                <MDBIcon
                    icon={"trash"}
                    size="2x"
                    style={{ color: "red", marginLeft: "1em" }}
                    data-tip={"Remove this product"}
                    onClick={() => this.handleProductRemove(product, index)}
                />
                <ReactTooltip />
            </div>
        );
    }

    renderTableToolbar() {
        let eOrder = this.state.eOrder;

        function renderOrderedDate(eOrder) {
            if (eOrder.equipmentOrderedDate) {
                return (
                    <p style={{ textAlign: "left" }}>
                        Ordered Date: {eOrder.equipmentOrderedDate}
                    </p>
                );
            }
        }

        return (
            <MDBCardHeader
                style={{ paddingBottom: 0, backgroundColor: "#5881c1" }}
            >
                <MDBRow>
                    <MDBCol size="3">{this.renderCategoryDropdown()}</MDBCol>
                    <MDBCol size="3">{this.renderProductDropdown()}</MDBCol>

                    <MDBCol
                        size="3"
                        style={{
                            textAlign: "center",
                            marginTop: "auto",
                            marginBottom: "auto",
                            color: "white",
                        }}
                    >
                        <p>
                            Total Approved Pts:{" "}
                            {eOrder.points && eOrder.points > 0
                                ? eOrder.points.toFixed(3)
                                : ""}
                        </p>
                    </MDBCol>

                    <MDBCol
                        size="3"
                        style={{
                            textAlign: "center",
                            margin: "auto",
                            color: "white",
                        }}
                    >
                        {renderOrderedDate(eOrder)}
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
        );
    }

    renderFlatRow(val, idx) {
        return (
            <div id={val} key={val} style={{ paddingTop: 1, paddingBottom: 1 }}>
                <span>{val}</span>
            </div>
        );
    }

    renderRow(property, productAttribute, index, product) {
        const { companyName, currentUser, productTypes, allBaseUnits, allVendors } = this.context;
        let pt = product && product !== null && product.productTypeId && product.productTypeId !== null ? productTypes.filter(x => x.id === product.productTypeId)[0] : null;
        let statusSetup = this.state.order.status === 3;
        let curBaseUnit = product && product !== null && product.baseUnitId && product.baseUnitId !== null ? allBaseUnits.find((b) => b.id === product.baseUnitId) : null;
        let vendor = null;
        if (curBaseUnit && curBaseUnit !== null) {
            vendor = allVendors.find((v) => v.id === curBaseUnit.vendorId);
        }

        if (property === "points") {
            let cu = currentUser;
            //if (cu !== null || cu !== undefined) {
            //    cu = JSON.parse(cu);
            //}
            let p = productAttribute.toString();
            return (
                <MDBInput
                    disabled={
                        cu.canEditPoints !== true
                    }
                    style={{ textAlign: "center", width: 80, margin: 0 }}
                    containerClass={"tableInputContainer"}
                    outline
                    step={0.1}
                    type={"number"}
                    value={p === "" ? "" : p}
                    onChange={this.handleProductChange.bind(
                        this,
                        "points",
                        index,
                        product
                    )}
                />
            );
        } else if (property === "qty") {
            let q = productAttribute;
            return (
                <MDBInput
                    style={{ textAlign: "center", width: 80, margin: 0 }}
                    type={"number"}
                    containerClass={"tableInputContainer"}
                    outline
                    disabled={statusSetup}
                    value={q === null || q === 0 || q === "0" ? "1" : q.toString()}
                    onChange={this.handleProductChange.bind(
                        this,
                        "qty",
                        index,
                        product
                    )}
                />
            );
        } else if (property === "serial") {
            let p = productAttribute;
            if (pt.requiresSerialNumber === 1 || pt.requiresSerialNumber === true) {
                return (
                    product.serial && product.serial !== null ? (
                        <MDBRow style={{ alignItems: "center" }}>
                            <MDBCol size="7">{productAttribute}
                            </MDBCol>
                            <MDBCol size="1">
                                <MUIIconButton
                                    size="small"
                                    color="primary"
                                    aria-label="edit serial number icon"
                                    onClick={() => this.setState({
                                        setProductDialogOpen: true,
                                        curProduct: product,
                                        curIndex: index,
                                        baseUnitVendor: vendor ? { label: vendor.name, value: vendor.id } : null,
                                        uSerial: product.serial,
                                        uBaseUnitId: product.baseUnitId ? product.baseUnitId : null,
                                        selectedBaseUnit: vendor && curBaseUnit && curBaseUnit.vendorId === vendor.id ? { label: curBaseUnit.name, value: curBaseUnit.id } : null
                                    })}
                                >
                                    <MUIEditIcon />
                                </MUIIconButton>
                            </MDBCol>
                        </MDBRow>
                    ) : (
                        <MUIButton
                            variant="outlined"
                            size="small"
                            onClick={() => this.setState({
                                setProductDialogOpen: true,
                                curProduct: product,
                                curIndex: index,
                                baseUnitVendor: vendor ? { label: vendor.name, value: vendor.id } : null,
                                uSerial: product.serial,
                                uBaseUnitId: product.baseUnitId ? product.baseUnitId : null,
                                selectedBaseUnit: vendor && curBaseUnit && curBaseUnit.vendorId === vendor.id ? { label: curBaseUnit.name, value: curBaseUnit.id } : null
                            })}
                        >
                            Receive Product
                        </MUIButton>
                    )
                );
            } else {
                return (
                    <MDBInput
                        style={{ textAlign: "center", maxWidth: "200px", margin: 0 }}
                        type={"text"}
                        containerClass={"tableInputContainer"}
                        outline
                        disabled={statusSetup}
                        value={p && p !== null ? p : ""}
                        onChange={this.handleProductChange.bind(
                            this,
                            "serial",
                            index,
                            product
                        )}
                    />
                );
            }

        } else if (property === "approved") {
            let a = productAttribute || false;
            return (
                <Input
                    id={"checkboxAPR" + index}
                    filled
                    disabled={statusSetup}
                    containerClass={"checkbox-approved-filled"}
                    label="approve"
                    type="checkbox"
                    checked={a}
                    onChange={this.handleCheckBoxChange.bind(
                        this,
                        "approved",
                        index,
                        product,
                        a
                    )}
                />
            );
        } else if (property === "btID" && companyName !== "corkmedical") {
            let a = productAttribute || false;
            return (
                <Input
                    id={"checkboxBTID" + index}
                    filled
                    //TODO add a disable check based on role? MB
                    disabled={false}
                    containerClass={"checkbox-btid-filled"}
                    label="BTID"
                    type="checkbox"
                    checked={a}
                    onChange={this.handleCheckBoxChange.bind(
                        this,
                        "BTID",
                        index,
                        product,
                        a
                    )}
                />
            );
        } else if (property === "abnRequired") {
            let a = productAttribute || false;
            return (
                <Input
                    id={"checkboxABN" + index}
                    disabled={statusSetup}
                    filled
                    label="abn"
                    type="checkbox"
                    checked={a}
                    onChange={this.handleCheckBoxChange.bind(
                        this,
                        "abnRequired",
                        index,
                        product,
                        a
                    )}
                />
            );
        } else if (property === "amount") {
            let c = productAttribute.toString();
            return (
                <MDBInput
                    style={{ textAlign: "center", width: 100, margin: 0 }}
                    outline
                    containerClass={"tableInputContainer"}
                    type={"number"}
                    disabled={statusSetup}
                    value={c === "" ? "" : c}
                    onChange={this.handleProductChange.bind(
                        this,
                        "amount",
                        index,
                        product
                    )}
                />
            );
        } else if (property === "warranty") {
            let a = productAttribute || false;
            return (
                <Input
                    id={"checkboxWARR" + index}
                    containerClass={"checkbox-warranty-filled"}
                    disabled={statusSetup}
                    filled
                    label="warranty"
                    type="checkbox"
                    checked={a}
                    onChange={this.handleCheckBoxChange.bind(
                        this,
                        "warranty",
                        index,
                        product,
                        a
                    )}
                />
            );
        } else if (property === "isRental") {
            let a = productAttribute || false;
            return (
                <Input
                    id={"checkboxRental" + index}
                    containerClass={"checkbox-warranty-filled"}
                    disabled={statusSetup}
                    filled
                    label="rental"
                    type="checkbox"
                    checked={a}
                    onChange={this.handleCheckBoxChange.bind(
                        this,
                        "isRental",
                        index,
                        product,
                        a
                    )}
                />
            );
        }
    }

    toggleProductDialog() {
        let curState = this.state.setProductDialogOpen;

        this.setState({
            setProductDialogOpen: !curState,
            curProduct: {},
            curIndex: null,
            baseUnitVendor: null,
            selectedBaseUnit: null,
            uSerial: null,
        });
    }

    renderProductDialog() {
        const { allBaseUnits } = this.context;
        let vendors = this.getVendorList();

        let filteredBaseUnits = [];

        if (this.state.curProduct && this.state.curProduct !== null) {
            if (this.state.baseUnitVendor && this.state.baseUnitVendor !== null) {
                filteredBaseUnits = allBaseUnits.filter((b) => b.vendorId === this.state.baseUnitVendor.value);
                filteredBaseUnits = this.getBaseUnitList(filteredBaseUnits);
            }
        }

        return (
            <React.Fragment>
                <MUIDialog
                    maxWidth="md"
                    open={this.state.setProductDialogOpen}
                    onClose={() => this.toggleProductDialog()}
                >
                    <MUIDialogTitle variant="h6" color="textPrimary">
                        {"Receive Product"}
                    </MUIDialogTitle>
                    <MUIDialogContent>
                        <MUIDialogContentText variant="body1" color="textPrimary">
                            <MDBRow>
                                <MDBCol>
                                    Select a base unit and enter the serial number of the actual product received for this order.
                                </MDBCol>
                            </MDBRow>
                            <br />
                            <MDBRow>
                                <MDBCol>
                                    <Select
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        value={this.state.baseUnitVendor || null}
                                        placeholder={"Vendor..."}
                                        options={vendors.sort((a, b) => a.label.localeCompare(b.label))}
                                        onChange={(e) => this.setState({ baseUnitVendor: e, selectedBaseUnit: null })}
                                    />
                                </MDBCol>
                                <MDBCol>
                                    <Select
                                        id="baseUnitDropdown"
                                        isDisabled={!this.state.baseUnitVendor}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        placeholder={"Base Unit..."}
                                        value={this.state.selectedBaseUnit || null}
                                        options={filteredBaseUnits.sort((a, b) => a.label.localeCompare(b.label))}
                                        onChange={(e) => this.setState({
                                            uBaseUnitId: e.value,
                                            selectedBaseUnit: e,
                                        })}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <br />
                            <MDBRow>
                                <MDBCol>
                                    <MUITextfield
                                        fullWidth
                                        label="Serial Number"
                                        variant="standard"
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        required
                                        value={this.state.uSerial || ""}
                                        onChange={(e) => this.setState({
                                            uSerial: e.target.value
                                        })}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MUIDialogContentText>
                    </MUIDialogContent>
                    <MUIDialogActions>
                        <MUIButton onClick={() => this.toggleProductDialog()}>Cancel</MUIButton>
                        <MUIButton variant="contained" onClick={() => {
                            this.validateAndUpdateReceiveProduct(this.state.curIndex, this.state.curProduct);
                        }} autoFocus>
                            Confirm
                        </MUIButton>
                    </MUIDialogActions>
                </MUIDialog>
            </React.Fragment>
        );

    }


    handleProductRemove(product, index) {
        let o = this.state.eOrder;

        if (this.state.order.status === 3) {
            return;
        }

        if (this.state.orderProducts.length <= 1) {
            toast.error("Cannot remove if there is only one product");
        } else {
            var dt = this.state.orderProducts,
                uProds = this.state.updateProducts;
            var it = uProds.filter(x => x.index !== index);

            it.forEach(x => {
                if (x.index > index)
                    x.index--;
            });
            //dt.splice(index, 1);
            //this.setState({
            //    orderProducts: dt,
            //    updateProducts: it,
            //    productsLoaded: false,
            //}, () => this.updateTableRows());

            if (product.id && product.id > 0) {
                OrderService.removeOrderProduct(product.id)
                    .then(res => {
                        if (res) {
                            toast.success("Product removed!");
                            //this.getOrderProducts(this.state.id);
                            this.setState({
                                //orderProducts: dt,
                                updateProducts: it,
                                productsLoaded: false,
                            }, () => {
                                if (o.abnProductOther) {
                                    this.removeABN(product);
                                }
                                this.getOrderProducts(o.id);
                            });
                        }
                        else
                            toast.error("There was an issue deleting the product. If the issue persists, refresh the page and try again.");
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
            else {
                dt.splice(index, 1);
                this.setState({
                    orderProducts: dt,
                    updateProducts: it,
                    productsLoaded: false,
                }, () => {
                    if (o.abnProductOther) {
                        this.removeABN(product);
                    }
                    this.updateTableRows();
                });

            }
        }
    }

    handleProductChange = (property, index, product, e) => {
        const { currentUser, companyName } = this.context;
        let d = this.state.data,
            uProds = this.state.updateProducts;

        var prods = this.state.orderProducts || [];
        var idx = prods.indexOf(product);
        let op = null,
            inst = this.state.eOrder.insuranceType ? this.state.eOrder.insuranceType.name : "None";
        let shouldCalculateCost = (companyName !== "rehabmedical" &&
            ["Long Term Care", "Dealer", "Hospital"].includes(inst));

        if (idx > -1) {
            op = prods[index];
        } else {
            op = prods.find((prod) => {
                if (product.id) {
                    return prod.id === product.id;
                } else {
                    // don't need productTypeId, but this still isn't a great way to do this
                    return (
                        prod.productSubTypeId ===
                        product.productSubTypeId
                    );
                }
            });
        }

        if (op) {
            if (property === "points") {
                op[property] = parseFloat(e.target.value);
            } else if (property === "amount" || property === "qty") {
                if (property === "qty") {
                    if (!e.target.value || e.target.value === "0") {
                        e.target.value = 1;
                    }
                }
                let currentQty = op.qty;
                op[property] = parseFloat(e.target.value);

                if (shouldCalculateCost) {
                    if (property === "qty") {
                        op[property] = parseInt(e.target.value);
                        let individualCost = op["amount"] / currentQty ? op["amount"] / currentQty : 0;
                        let newAmount = individualCost * op[property];
                        op.amount = newAmount;
                        d.rows[index]["amount"] = this.renderRow(
                            "amount",
                            parseFloat((newAmount).toFixed(2)).toString(),
                            index,
                            op
                        );
                    }

                    op.points = parseFloat((op.amount / 5000).toFixed(2));
                    d.rows[index]["points"] = this.renderRow(
                        "points",
                        parseFloat((op.amount / 5000).toFixed(2)),
                        index,
                        op
                    );
                }
            } else if (property === "baseUnitId") {
                op[property] = e.value;
            }
            else {
                op[property] = e.target.value;
            }
            d.rows[index][property] = this.renderRow(
                property,
                property === "baseUnitId" ? e.value : e.target.value,
                index,
                op
            );
            op.updatedById = currentUser.id;
            op.updatedOn = new Date().toISOString();

            if (uProds && uProds.length > 0 && uProds.filter(x => x.index === index).length > 0)
                uProds.filter(x => x.index === index)[0].product = op;
            else
                uProds.push({ index: index, product: op });
        }

        this.setState({
            data: d,
            orderProducts: prods,
            updateProducts: uProds,
        });
    };

    handleCheckBoxChange = (property, index, product, value, e) => {
        const { companyName, currentUser } = this.context;
        let o = JSON.parse(JSON.stringify(this.state.eOrder)),
            d = this.state.data,
            products = this.state.orderProducts,
            uProds = this.state.updateProducts;

        if (property === "BTID" && companyName !== "corkmedical") {
            this.toggleBTIDModalPopup();
            return;
        }

        let op = products.find((x, idx) => {
            if (product.id && product.id > 0 && x.id > 0) {
                return x.id === product.id;
            } else {
                return (
                    idx === index
                );
            }
        });

        if (op) {
            op[property] = !value;
            op.updatedOn = new Date().toISOString();
            op.updatedById = currentUser.id;
            d.rows[index][property] = this.renderRow(
                property,
                !value,
                index,
                op
            );
            if (uProds && uProds.length > 0 && uProds.filter(x => x.index === index).length > 0)
                uProds.filter(x => x.index === index)[0].product = op;
            else
                uProds.push({ index: index, product: op });
        }

        this.setState({
            data: d,
            updateProducts: uProds,
        });
        if (property === "abnRequired") {
            this.addABNProduct(index, o);
        }
    };

    addABNProduct(index, o) {
        let d = this.state.data,
            r = d.rows[index];

        let abn = o.abnProductOther,
            abnArr = abn ? abn.split(",") : "";

        let cat = r.category.key.replace(/,/g, ""),
            sub = r.productSub.key.replace(/,/g, "");

        let exIdx = abnArr.indexOf(cat + " " + sub);
        // in try/catch since abnProductOther can be null from the order obj...JK
        try {
            // if len is zero then you can just added product to property
            if (abn.length === 0) {
                o.abnProductOther = cat + " " + sub;
                // if the product is present in abnProductOther property already then remove it
                // since that means they unchecked the ABN checkbox...JK
            } else if (exIdx > -1) {
                abnArr.splice(exIdx, 1);
                o.abnProductOther = abnArr.toString();
                // product isn't present in abnProductOther yet so it's safe to add
                // and that means the user checked the ABN checkbox...JK
            } else {
                abnArr.push(cat + " " + sub);
                o.abnProductOther = abnArr.toString();
            }
            // abnProductOther was null so just add the product to the property...JK
        } catch (e) {
            o.abnProductOther = cat + " " + sub;
        }
        this.setState({
            eOrder: o,
        });
    }

    removeABN(product) {
        const { productTypes, productSubTypes } = this.context;
        let o = this.state.eOrder;
        let abnArr = o.abnProductOther.split(",");
        let pt = product.productTypeId ? productTypes.filter(x => x.id === product.productTypeId)[0] : null;
        let pst = product.productSubTypeId ? productSubTypes.filter(x => x.id === product.productSubTypeId)[0] : null;

        if (
            abnArr.includes(
                pt.name +
                " " +
                (pst ? pst.name : "")
            )
        ) {
            let i = abnArr.indexOf(
                pt.name +
                " " +
                (pst ? pst.name : "")
            );
            abnArr.splice(i, 1);
            o.abnProductOther = abnArr.toString();
            this.setState({
                eOrder: o,
            });
        }
    }

    approveAllProducts() {
        let eOrder = this.state.eOrder,
            d = this.state.data,
            a = this.state.allApproved,
            prods = this.state.orderProducts,
            uProds = this.state.updateProducts;

        prods.forEach((prod, idx) => {
            prod.approved = !a;
            d.rows[idx]["approved"] = this.renderRow(
                "approved",
                !a,
                idx,
                prod.products
            );
            if (uProds && uProds.length > 0 && uProds.filter(x => x.index === idx).length > 0)
                uProds.filter(x => x.index === idx)[0].product = prod;
            else
                uProds.push({ index: idx, product: prod });
        });

        this.setState({
            eOrder: eOrder,
            data: d,
            allApproved: !a,
            orderProducts: prods,
            updateProducts: uProds,
        });
    }

    renderProductTable() {
        const { companyName } = this.context;
        let d = this.state.data;

        if (!this.state.productsLoaded)
            return <MDBSpinner />;

        return (
            <MDBTable
                small
                hover
                className="rounded mb-0 table-box-shadow smallTable"
            >
                <TableHead style={{ backgroundColor: "#46679a" }} textWhite>
                    <tr>
                        {d.columns.map((col, idx) => {
                            if (col.label === "Approved") {
                                return (
                                    <th
                                        key={idx}
                                        style={{
                                            padding: 8,
                                            cursor: "pointer",
                                        }}
                                        onClick={this.approveAllProducts.bind(
                                            this
                                        )}
                                        data-tip={"Approve All"}
                                    >
                                        Approved
                                    </th>
                                );
                            }

                            if (col.label === "ABN Required") {
                                return (
                                    <th
                                        key={idx}
                                        style={{
                                            padding: 8,
                                            cursor: "pointer",
                                        }}
                                        data-tip={"View ABN Information"}
                                        onClick={this.viewABNClicked}
                                    >
                                        ABN
                                    </th>
                                );
                            }

                            if (col.label === "btID") {
                                if (companyName !== "corkmedical") {
                                    return (
                                        <th
                                            width={50}
                                            key={idx}
                                            style={{
                                                padding: 8,
                                                cursor: "pointer",
                                            }}
                                            data-tip={"View Product btID's"}
                                            onClick={this.toggleBTIDModalPopup}
                                        >
                                            BTID
                                        </th>
                                    );
                                } else {
                                    return null;
                                }
                            }

                            return (
                                <th style={{ padding: 8 }} key={idx}>
                                    {col.label}
                                </th>
                            );
                        })}
                    </tr>
                </TableHead>
                <TableBody rows={d.rows} />
            </MDBTable>
        );
    }

    updateParentObject(obj) {
        this.setState({
            eOrder: obj,
        });
    }

    updateOrderNotes(note) {
        let notes = this.state.notes;

        notes.unshift(note);
        this.setState({
            notes: notes,
        });
    }

    renderPQModal() {
        let st = this.state;
        if (!st.pqModal) {
            return;
        }
        return (
            <MDBModal
                size="lg"
                isOpen={st.pqModal}
                toggle={() => {
                    return;
                }}
            >
                <MDBModalHeader>Quotes</MDBModalHeader>
                <ModalBody
                    style={{
                        backgroundColor: "#FFFFFF",
                        padding: 2,
                        margin: 2,
                    }}
                    className="mx-3"
                >
                    <PqQuoteSelector
                        files={st.eOrder.activity.files}
                        currQueue={st.purchaseQueues}
                        closeModal={() => this.setState({ pqModal: false })}
                        quoteChosen={(obj) => {
                            this.savePurchaseQueue(obj);
                        }}
                    />
                </ModalBody>
            </MDBModal>
        );
    }

    renderBTIDModal() {
        const { companyName, productTypes, productSubTypes } = this.context;
        if (companyName !== "corkmedical") {
            let st = this.state;
            let prods = this.state.orderProducts,
                p =
                    this.state.btitems ||
                    (prods &&
                        prods.length > 0
                        ? prods
                        : []); //.sort((a, b) => {
            //    return b.points - a.points;
            //}) : []);

            if (!st.BTIDModal) {
                return;
            }
            if (this.state.idsLoaded) {
                let btids = this.state.idNames;
                return (
                    <div className="overFlowFix">
                    <MDBModal
                        size="lg"
                        isOpen={st.BTIDModal}
                        toggle={() => {
                            this.toggleBTIDModalPopup();
                        }}
                        className="salesPilotModal"
                    >
                        <MDBModalHeader>Brightree Item IDs</MDBModalHeader>
                        <ModalBody>
                            <div>
                                <MDBRow
                                    style={{
                                        textDecoration: "underline",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <MDBCol size="4">Product </MDBCol>
                                    <MDBCol size="4">Brightree Product </MDBCol>
                                    <MDBCol size="1">Rental </MDBCol>
                                    <MDBCol size="3">Serial </MDBCol>
                                </MDBRow>

                                {p.map((prod, idx) => {
                                    //let options = btids.find(x => x.productId === prod.id).btids;
                                    let options = btids[idx],
                                        pt = prod.productTypeId ? productTypes.filter(x => x.id === prod.productTypeId)[0] : null,
                                        pst = prod.productSubTypeId ? productSubTypes.filter(x => x.id === prod.productSubTypeId)[0] : null;

                                    return (
                                        <MDBRow key={idx} className={"eqRow"}>
                                            <MDBCol size="4">
                                                {pt.name +
                                                    " " +
                                                    (pst ? pst.name : "")}
                                            </MDBCol>
                                            <MDBCol size="4">
                                                <MDBSelect
                                                    className={
                                                        "smallMargin searchField"
                                                    }
                                                    outline={true}
                                                    search={true}
                                                    color="info"
                                                    selected={
                                                        prod.btitemId ||
                                                        "Choose Product"
                                                    }
                                                    style={{ margin: "5px" }}
                                                    // options={this.state.idNames[idx]}

                                                    options={options}
                                                    getValue={this.handleIDChange.bind(
                                                        this,
                                                        prod
                                                    )}
                                                    id={"btinput" + idx}
                                                    destroy={true}
                                                />
                                            </MDBCol>
                                            <MDBCol size="1">
                                                <Input
                                                    id={"rental_" + idx}
                                                    filled
                                                    containerClass={
                                                        "checkbox-btid-filled"
                                                    }
                                                    type="checkbox"
                                                    checked={
                                                        prod.isRental || false
                                                    }
                                                    onChange={this.handleRentalChange.bind(
                                                        this,
                                                        prod
                                                    )}
                                                />
                                            </MDBCol>
                                            <MDBCol size="3">
                                                {prod.serial}
                                            </MDBCol>
                                        </MDBRow>
                                    );
                                })}
                            </div>
                        </ModalBody>
                        <MDBModalFooter>
                            <MDBRow>
                                <MDBCol>
                                    <Button
                                        floating
                                        rounded
                                        size="sm"
                                        data-tip={"Save"}
                                        //disabled={true}
                                        //TODO disable check? MB
                                        //disabled={onFile === true && this.state.ssAcknowledge !== true}
                                        color={"success"}
                                        onClick={() => {
                                            this.saveCancelBTID("save");
                                        }}
                                    >
                                        <MDBIcon
                                            fas
                                            icon="check"
                                            style={{ fontSize: "2em" }}
                                        />
                                    </Button>
                                </MDBCol>
                                <MDBCol>
                                    <Button
                                        floating
                                        size="sm"
                                        color={"red"}
                                        data-tip={"Cancel"}
                                        onClick={() => {
                                            this.saveCancelBTID("cancel");
                                        }}
                                    >
                                        <MDBIcon
                                            icon="times"
                                            style={{ fontSize: "2em" }}
                                        />
                                    </Button>
                                </MDBCol>
                            </MDBRow>
                            <ReactTooltip />
                        </MDBModalFooter>
                        </MDBModal>
                    </div>
                );
            } else {
                return (
                    <div className="overFlowFix">
                    <MDBModal
                        size="lg"
                        isOpen={st.BTIDModal}
                        toggle={() => {
                            this.toggleBTIDModalPopup();
                        }}
                        className="salesPilotModal"
                    >
                        <MDBModalHeader>Brightree Item IDs</MDBModalHeader>
                        <ModalBody>
                            <MDBRow>
                                <MDBCol
                                    style={{ textAlign: "center" }}
                                    size="12"
                                >
                                    <Spinner multicolor />
                                </MDBCol>
                            </MDBRow>
                        </ModalBody>
                        <MDBModalFooter>
                            <MDBRow>
                                <MDBCol>
                                    <Button
                                        floating
                                        rounded
                                        size="sm"
                                        data-tip={"Save"}
                                        disabled={true}
                                        color={"success"}
                                    >
                                        <MDBIcon
                                            fas
                                            icon="check"
                                            style={{ fontSize: "2em" }}
                                        />
                                    </Button>
                                </MDBCol>
                                <MDBCol>
                                    <Button
                                        disabled={true}
                                        floating
                                        size="sm"
                                        color={"red"}
                                        data-tip={"Cancel"}
                                    >
                                        <MDBIcon
                                            icon="times"
                                            style={{ fontSize: "2em" }}
                                        />
                                    </Button>
                                </MDBCol>
                            </MDBRow>
                            <ReactTooltip />
                        </MDBModalFooter>
                        </MDBModal>
                    </div>
                );
            }
        }
    }

    saveCancelBTID(option) {
        const { companyName } = this.context;
        if (companyName !== "corkmedical") {
            let items = this.state.btitems;
            let prods = this.state.orderProducts,
                uProds = this.state.updateProducts;

            if (option === "save" && items) {
                items.forEach((pr, ind) => {
                    var prod = prods[ind];
                    prod.btitemId = pr.btitemId;
                    prod.isRental = pr.isRental;
                    prod.updatedById = pr.updatedById;
                    prod.updatedOn = new Date().toISOString();
                    if (uProds && uProds.length > 0 && uProds.filter(x => x.index === ind).length > 0)
                        uProds.filter(x => x.index === ind)[0].product = prod;
                    else
                        uProds.push({ index: ind, product: prod });
                });
                this.toggleBTIDModalPopup();
                setTimeout(() => {
                    this.updateTableRows();
                }, 50);
            } else {
                this.setState({
                    btitems: null,
                });
                this.toggleBTIDModalPopup();
            }
        }
    }

    handleIDChange = (e, prod) => {
        const { currentUser, companyName } = this.context;
        if (prod.length === 0 || companyName === "corkmedical") {
            return;
        }

        let items = this.state.btitems;
        if (!items) {
            items = JSON.parse(JSON.stringify(this.state.orderProducts));
        }

        //let products = items.sort((a, b) => {
        //    return b.points - a.points;
        //});
        let index = items.indexOf(e);
        if (index > -1) {
            items[index].btitemId = prod[0];
            items[index].updatedById = currentUser.id;

            this.setState({
                btitems: items,
            });
        } else {
            let activityProducts = items.map((product) => {
                if (
                    (product.id && product.id === e.id) ||
                    (product.productTypeId === e.productTypeId &&
                        product.productSubTypeId === e.productSubTypeId)
                ) {
                    product.btitemId = prod[0];
                    product.updatedById = currentUser.id;
                }
                return product;
            });
            this.setState({
                btitems: activityProducts,
            });
        }
    };

    handleRentalChange = (e, p) => {
        const { currentUser } = this.context;
        if (p.length === 0) {
            return;
        }

        let items = this.state.btitems;
        if (!items) {
            items = this.state.orderProducts;
        }

        //let products = items.sort((a, b) => {
        //    return b.points - a.points;
        //});

        let index = items.indexOf(e);

        if (index > -1) {
            items[index].isRental =
                items[index].isRental === true ? false : true;
            items[index].updatedById = currentUser.id;

            this.setState({
                btitems: items,
            });
        } else {
            let activityProducts = items.map((product) => {
                if (
                    (product.id && product.id === e.id) ||
                    (product.productTypeId === e.productTypeId &&
                        product.productSubTypeId === e.productSubTypeId)
                ) {
                    product.isRental = product.isRental === true ? false : true;
                    product.updatedById = currentUser.id;
                }
                return product;
            });
            this.setState({
                btitems: activityProducts,
            });
        }
    };

    getIDs() {
        const { companyName, productSubTypes } = this.context;
        if (companyName !== "corkmedical") {
            let products = this.state.orderProducts;

            if (products.length > 0) {
                products.forEach((product, index) => {
                    var hcp = product.hcpcs;
                    if (!hcp) {
                        hcp = product.productSubTypeId ? productSubTypes.filter(x => x.id === product.productSubTypeId)[0].hcpcs : null;
                    }
                    this.getProductsDetails(hcp, index, product.id);
                });
            }
        }
    }

    getProductsDetails(hcpcs, index) {
        if (this.context.companyName === "corkmedical") {
            return;
        }

        let a = [];
        a.push({
            text: "***Clear***",
            value: null,
        });
        a.push({
            text: "N/A",
            value: "N/A",
        });

        BtProductService.getBtProductsByHcpcs(hcpcs)
            .then((res) => {
                if (res && res.length > 0) {
                    res.map((product) => {
                        return a.push({
                            text: product,
                            value: product,
                        });
                    });
                }
                let productList = this.state.idNames;
                productList[index] = a;
                const idsLoadedCount = (this.state.idsLoadedCount || 0) + 1;
                this.setState({
                    idsLoadedCount: idsLoadedCount,
                    idNames: productList,
                    idsLoaded: idsLoadedCount === this.state.orderProducts.length,
                });
            })
            .catch(() => {
                const idsLoadedCount = (this.state.idsLoadedCount || 0) + 1;
                this.setState({
                    idsLoadedCount: idsLoadedCount,
                    idsLoaded: idsLoadedCount === this.state.orderProducts.length,
                });
            });
    }

    generateTimelineEntry() {
        if (this.state.timelineModalEntries == null) {
            return;
        }
        let sorted = []
            .concat(this.state.timelineModalEntries)
            .sort(function (a, b) {
                return new Date(a.updatedOn) - new Date(b.updatedOn);
            });
        // console.log(sorted);
        return sorted.map((att, idx) => {
            let v = true;
            if (idx === 0 || idx % 2 === 0) {
                v = false;
            }
            return (
                <TimelineStep
                    color="red-gradient darken-4"
                    href="#void"
                    inverted={v}
                    key={idx}
                    icon="calendar-check-o"
                >
                    <span style={{ fontSize: "0.88rem" }}>
                        {GlobalFunctions.formatDateTime(att.updatedOn)}
                        <br />
                        {att.updatedBy}
                    </span>
                    <br />
                    <label style={{ fontWeight: 400 }}>
                        {this.formatTimelineStatus(att)}
                    </label>
                    <hr />
                    <span>{att.reason}</span>
                </TimelineStep>
            );
        });
    }

    createFollowupNote() {
        const { currentUser } = this.context;

        let cb = { id: currentUser.id, name: currentUser.username };

        let note = {
            task: false,
            completed: true,
            assignedTo: this.state.eOrder.activity.owner,
            assignedToId: this.state.eOrder.activity.ownerId,
            createdAt: GlobalFunctions.getUTCMoment(),
            createdBy: cb,
            createdById: cb.id,
            account: this.state.eOrder.activity.account,
            accountId: this.state.eOrder.activity.accountId,
            patientName: this.state.eOrder.patientName,
            activityId: this.state.eOrder.id,
        };

        let nText = document.getElementById("newFollowupNoteText").value;
        note.text = nText;

        function addWeekdays(date, days) {
            date = moment(date); // use a clone
            while (days > 0) {
                date = date.add(1, "days");
                // decrease "days" only if it's a weekday.
                if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                    days -= 1;
                }
            }
            return date.toISOString();
        }

        return NoteService.createActivityNote(this.state.eOrder.id, note)
            .then((res) => {
                //need to set the last note date because otherwise the save overrides it...BC
                //let ord = this.state.eOrder;
                //ord.activity.lastNoteDate = new Date().toISOString();

                //this.addNoteUpdateOrderCloseModal(res, ord);
                if (res.createdAt.indexOf("Z") > 0) {
                    res.createdAt = res.createdAt.split("Z")[0];
                }
                this.noteAdded(res);

                let obj = {
                    name: this.state.eOrder.patientName,
                    orderActivityId: this.state.eOrder.id,
                    followUpOn: new Date().toISOString(),
                    //followUpBy: cb,
                    followUpById: cb.id,
                    followUpNoteId: res.id,
                };

                this.setState({
                    followupModalOpen: false,
                    //eOrder: o
                });

                NoteService.createOrderFollowup(obj)
                    .then((result) => {
                        let o = this.state.eOrder;

                        //this method is overridden.  Only update the followUpDate if we are in the Follow Up Task
                        //if (
                        //    o.orderStatusReason &&
                        //    o.orderStatusReason.description === "Follow Up Task"
                        //) {
                        if ([6, 60, 89, 90].includes(o.orderStatusReasonId) || this.state.goto === "followUp" || (o.orderStatusReason &&
                            o.orderStatusReason.description === "Follow Up Task")) {
                            o.followUpDate = addWeekdays(
                                new Date(),
                                this.state.followUpDays
                            );
                        }

                        this.setState({
                            followupModalOpen: false,
                            eOrder: o,
                        });
                        this.saveOrder(o);
                    })
                    .catch((errRes) => {
                        console.log(errRes);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderOrderTimeline() {
        return <Timeline>{this.generateTimelineEntry()}</Timeline>;
    }

    renderTimelineModal() {
        return (
            <div>
                <Modal
                    className={"extraWidth"}
                    isOpen={this.state.timelineModalOpen}
                    toggle={() => this.toggleTimelineModalPopup()}
                    fullHeight
                    position="left"
                >
                    <ModalHeader
                        style={{
                            backgroundColor: "#1976D2",
                            color: "white",
                            borderBottom: "3px solid #145EA8",
                        }}
                    >
                        <h5>
                            {this.formatStatus(this.state.eOrder.status)} --
                            Updated:{" "}
                            {GlobalFunctions.formatDateTime(
                                this.state.eOrder.lastUpdatedOn || new Date()
                            )}
                        </h5>
                    </ModalHeader>
                    <ModalBody
                        className={"stepContainer"}
                        style={{ backgroundColor: "#555" }}
                    >
                        {this.renderOrderTimeline()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    decreaseFollowUpDays() {
        if (this.state.followUpDays > 0) {
            this.setState({ followUpDays: this.state.followUpDays - 1 });
        }
    }

    increaseFollowUpDays() {
        if (this.state.followUpDays < 5) {
            this.setState({ followUpDays: this.state.followUpDays + 1 });
        }
    }

    renderActionNoteModal() {
        let o = this.state.eOrder,
            reason =
                "Add " +
                (o.orderStatusReason
                    ? o.orderStatusReason.description
                    : "Action") +
                " Note",
            canFollowUp = false;

        if ([6, 60, 89, 90].includes(o.orderStatusReasonId) || //6 - follow up task, 60 - Service - Pre-Auth Follow Up, 89, Preauth Followup - Appeals, 90 Unable to Bill - Followup
            (o.orderStatusReason && ["Follow Up Task", "Service - Pre-Auth Follow Up", "Preauth Followup - Appeals", "Unable to Bill - Followup"].includes(o.orderStatusReason.description)) ||
            this.state.goto === "followUp") {
            canFollowUp = true;
        }

        if (o.orderStatusReasonId && o.orderStatusReasonId === 15) {
            return (
                <CreateNoteModal
                    eOrder={this.state.eOrder}
                    toggleProcessing={this.toggleProcessing.bind(this)}
                    toggleNoteModal={() => this.toggleFollowupNoteModal()}
                    addNoteUpdateOrderCloseModal={this.addNoteUpdateOrderCloseModal.bind(this)}
                    noteModalOpen={this.state.followupModalOpen}
                    isAutoVerification={true}
                    onRadioChange={(e) => this.setState({ selectedVerificationNoteRadio: e.target.value === "true" })}
                    selectedVerificationNoteRadio={this.state.selectedVerificationNoteRadio}
                />
            )
        } else {
            return (
                <div>
                    <MDBModal
                        size={"lg"}
                        isOpen={this.state.followupModalOpen}
                        toggle={() => this.toggleFollowupNoteModal()}
                    >
                        <MDBModalHeader>{reason}</MDBModalHeader>
                        <MDBModalBody>
                            <textarea
                                placeholder={"Add note text here."}
                                autoFocus
                                id={"newFollowupNoteText"}
                                style={{ width: "100%", height: "200px" }}
                            ></textarea>
                        </MDBModalBody>
                        <MDBModalFooter>
                            {canFollowUp ? (
                                <MDBCol size="2.5">
                                    <span>Follow up after </span>
                                </MDBCol>
                            ) : null}

                            {canFollowUp ? (
                                <MDBCol
                                    size="1.5"
                                    style={{
                                        paddingLeft: "3px",
                                        paddingRight: "3px",
                                    }}
                                >
                                    <div className="number-input">
                                        <button
                                            onClick={() =>
                                                this.decreaseFollowUpDays()
                                            }
                                            className="minus"
                                        />
                                        <input
                                            value={this.state.followUpDays}
                                            style={{
                                                width: "50px",
                                                textAlign: "center",
                                            }}
                                            disabled
                                        />
                                        <button
                                            onClick={() =>
                                                this.increaseFollowUpDays()
                                            }
                                            className="plus"
                                        />
                                    </div>
                                </MDBCol>
                            ) : null}

                            {canFollowUp ? (
                                <MDBCol
                                    size="6"
                                    style={{ marginLeft: "3px", padding: "0px" }}
                                >
                                    <span> days</span>
                                </MDBCol>
                            ) : null}

                            <MDBBtn
                                size="sm"
                                color="red"
                                data-tip="Close"
                                floating
                                onClick={() => this.toggleFollowupNoteModal()}
                            >
                                <MDBIcon icon="times" />
                            </MDBBtn>
                            <MDBBtn
                                size="sm"
                                data-tip={reason}
                                floating
                                color="green"
                                onClick={() => this.createFollowupNote()}
                            >
                                <MDBIcon icon="check" />
                            </MDBBtn>
                        </MDBModalFooter>
                        <ReactTooltip />
                    </MDBModal>
                </div>
            );
        }
    }

    renderApprovalTab() {
        if (this.state.eOrder.approvalDate) {
            return (
                <MDBNavItem>
                    <MDBNavLink
                        link
                        to="#"
                        role="tab"
                        active={this.state.TabsOneActiveItem === "4"}
                        onClick={() => {
                            this.toggle("1", "4");
                        }}
                    >
                        Approval
                    </MDBNavLink>
                </MDBNavItem>
            );
        }
    }

    renderApprovalTabPane() {
        const { productTypes, productSubTypes } = this.context;
        let eOrder = this.state.eOrder;

        if (eOrder.approvalDate) {
            return (
                <MDBTabPane tabId="4" role="tabpanel">
                    <ApprovalSummary
                        order={this.state.order}
                        products={this.state.orderProducts}
                    />
                </MDBTabPane>
            );
        }
    }

    renderTabSection() {
        return (
            <Row>
                <Col size="12" style={{ minWidth: "770px" }}>
                    <MDBNav
                        tabs
                        className={
                            this.state.TabsOneActiveItem === "3"
                                ? "nav-justified navFull nav-equipment"
                                : "nav-justified navFull"
                        }
                        color={"blue"}
                    >
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                role="tab"
                                active={this.state.TabsOneActiveItem === "1"}
                                onClick={() => {
                                    this.toggle("1", "1");
                                }}
                            >
                                Patient Information
                            </MDBNavLink>
                        </MDBNavItem>

                        {/* <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                role="tab"
                                active={this.state.TabsOneActiveItem === "2"}
                                onClick={() => {
                                    this.toggle("1", "2");
                                }}
                            >
                                Follow-Ups
                            </MDBNavLink>
                        </MDBNavItem> */}

                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                role="tab"
                                active={this.state.TabsOneActiveItem === "3"}
                                onClick={() => {
                                    this.toggle("1", "3");
                                }}
                            >
                                Equipment
                            </MDBNavLink>
                        </MDBNavItem>

                        {this.renderApprovalTab()}

                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                role="tab"
                                active={this.state.TabsOneActiveItem === "5"}
                                onClick={() => {
                                    this.toggle("1", "5");
                                }}
                            >
                                Notes
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                role="tab"
                                active={this.state.TabsOneActiveItem === "6"}
                                onClick={() => {
                                    this.toggle("1", "6");
                                }}
                            >
                                Attachments
                            </MDBNavLink>
                        </MDBNavItem>
                        {this.renderSeatingClinicTabLink()}
                    </MDBNav>
                    <MDBTabContent
                        className={
                            this.state.TabsOneActiveItem === "3"
                                ? "card-equipment card"
                                : this.state.TabsOneActiveItem === "4"
                                    ? "card-approval card"
                                    : "card"
                        }
                        activeItem={this.state.TabsOneActiveItem}
                    >
                        <MDBTabPane tabId="1" role="tabpanel">
                            <PatientInformationTab
                                eOrder={this.state.eOrder}
                                updateTableRows={this.updateTableRows.bind(
                                    this
                                )}
                                updateParentObject={this.updateParentObject.bind(
                                    this
                                )}
                                saveParentObject={(e) => this.saveOrder(null)}
                                toggleNoteModal={() =>
                                    this.toggleNoteModalPopup()
                                }
                                setNoteText={(t) => {
                                    this.setState({
                                        noteText: t || "",
                                    });
                                }}
                                handleOnClick={() => this.setState({ isAutoVerification: true })}
                                history={this.props.history}
                            />
                        </MDBTabPane>

                        {/* <MDBTabPane tabId="2" role="tabpanel">
                            <FollowupsTab
                                allNotes={this.state.followUpNotes}
                                eOrder={this.state.eOrder}
                            />
                        </MDBTabPane> */}

                        <MDBTabPane tabId="3" role="tabpanel">
                            {this.renderEquipmentCard()}
                        </MDBTabPane>
                        {this.renderApprovalTabPane()}
                        <MDBTabPane tabId="5" role="tabpanel">
                            <NotesTab
                                getNotes={() =>
                                    this.retrieveNotes(this.state.eOrder.id)
                                }
                                allNotes={this.state.notes}
                                eOrder={this.state.eOrder}
                            />
                        </MDBTabPane>
                        <MDBTabPane tabId="6" role="tabpanel">
                            <AttachmentsTab
                                clearReaload={this.clearReload.bind(this)}
                                reloadAttachments={this.state.reloadAttachments}
                                eOrder={this.state.eOrder}
                                updateParent={this.updateParentObject.bind(
                                    this
                                )}
                                saveOrder={(e) => this.saveOrder(null)}
                            />
                        </MDBTabPane>
                        {/*{this.renderSeatingClinicTabPane(theme)}*/}
                    </MDBTabContent>
                </Col>
            </Row>
        );
    }

    clearReload() {
        this.setState({ reloadAttachments: false });
    }
    setReload() {
        this.setState({ reloadAttachments: true });
    }
    renderDMWindow = () => {
        Sentry.captureMessage(
            `Delivery Manifest button clicked`,
            {
                fingerprint: ['orderPageButtonActivity']
            }
        );
        window.open(
            this.state.id.toString() + "/deliveryManifest",
            "_blank",
            "toolbar=yes,scrollbars=yes,resizable=yes,top=700,left=700,width=1000,height=800"
        );
    };

    renderBrightreeOrderID(order) {
        if (order) {
            return (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <a
                            href={
                                order.btSoId
                                    ? `https://brightree.net/F1/02200/OrbitMedical/OrderEntry/frmSOOrderRO.aspx?SalesOrderKey=${order.btSoId}`
                                    : "#"
                            }
                            target="_blank"
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#FFFFFF",
                                textAlign: "left",
                                textDecoration: order.btSoId ? "underline" : "none",
                                pointerEvents: order.btSoId ? "auto" : "none",
                            }}
                        >
                            {"Brightree ID: " + (order.btSoId || "N/A")}
                        </a>
                        {Security.hasPermission("order.updateBrightreeId") ? (
                            <>
                                <MUIButton
                                    size="small"
                                    style={{ marginLeft: "8px", color: "#FFFFFF" }}
                                    onClick={() => this.setState({ dialogUpdateBrightreeExternalIdOpen: true })}
                                >
                                    <MUIEditIcon fontSize="small" />
                                </MUIButton>
                                <DialogUpdateBrightreeExternalId
                                    open={this.state.dialogUpdateBrightreeExternalIdOpen}
                                    onClose={() => this.setState({ dialogUpdateBrightreeExternalIdOpen: false })}
                                    order={order}
                                    onConfirm={(order) => {
                                        order.lastUpdatedById = this.context.currentUser.id;
                                        return OrderService.updateOrder(order)
                                            .then((res) => {
                                                this.setState({
                                                    dialogUpdateBrightreeExternalIdOpen: false,
                                                    eOrder: res,
                                                });
                                                toast.success("Order updated successfully.");
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                                toast.error("There was an issue updating the order.");
                                            });
                                    }}
                                />
                            </>
                        ) : null}
                    </div>
                </>
            );
        }
    }

    renderViewToolbar() {
        let isNonbillable = this.state.orderProducts && this.state.orderProducts.length > 0 &&
            this.state.orderProducts.filter(x => x.approved === true).length > 0 ?
            this.state.orderProducts.filter(x => x.approved === true).map((e) => e.hcpcs && e.hcpcs.toLowerCase() === "nb") : [];

        let eOrder = this.state.eOrder,
            patient = eOrder.patient,
            btSoId = eOrder.btSoId,
            readyToPurchase = !!eOrder.approvalDate,
            equipOrdered = this.state.purchaseQueues.length > 0,
            dataTip = readyToPurchase
                ? "Purchase Equipment".concat(
                    equipOrdered ? " / View Equipment Status" : ""
                )
                : "Order must be approved first",
            lastChartNotes = this.state.lastChartNotes,
            chartTip = lastChartNotes && lastChartNotes.id ? lastChartNotes.id + "       " + lastChartNotes.filename + "       " + lastChartNotes.uploadedAt : "";

        let reason =
            "Add " +
            (this.state.eOrder.orderStatusReason
                ? this.state.eOrder.orderStatusReason.description
                : "Action") +
            " Note";

        function determineGoto(history) {
            window.history.back();
        }
   
        function renderDeliveryNoteIcon() {
            if (eOrder.deliveryNotes) {
                return (
                    <MDBIcon
                        style={{ color: "yellow", paddingLeft: 12 }}
                        icon="truck-moving"
                        size={"1x"}
                        data-place={"bottom"}
                        data-tip={eOrder.deliveryNotes}
                    />
                );
            }
        }

        return (
            <MDBCardHeader
                style={{
                    paddingTop: 8,
                    paddingBottom: 6,
                    marginBottom: 8,
                    color: "white",
                    fontSize: 18,
                    backgroundColor: "#5881C1FF",
                }}
            >
                <MDBRow style={{ padding: '.3%' }}>
                    <MDBCol md="3" style={{display: 'flex', alignItems: 'center'}}>
                        {window.history.length > 1 ?
                            <Route
                                render={({ history }) => (
                                    <MDBBtn
                                        className="px-2 toolbarButton"
                                        data-tip="Return to Previous Page"
                                        data-place={"right"}
                                        outline
                                        rounded
                                        size="sm"
                                        color={"warning"}
                                        onClick={() => {
                                            determineGoto(history);
                                        }}
                                        style={{ marginRight: '5%' }}
                                    >
                                        <MDBIcon icon="long-arrow-alt-left" />
                                    </MDBBtn>
                                )}
                            /> : <div></div>
                        }
                        <div>
                            <p
                                style={{
                                    fontSize: 20,
                                    color: isNonbillable.length > 0 && isNonbillable.every(v => v === true) ? "yellow" : "white",
                                    textAlign: 'left',
                                    margin: "auto",
                                    display: 'block',
                                }}
                            >
                                {"Order # " + eOrder.id}
                                {isNonbillable.length > 0 && isNonbillable.every(v => v === true) ? " - Non-Billable" : ""}
                            </p>
                           {this.renderBrightreeOrderID(eOrder)}
                        </div>
                    </MDBCol>
                    <MDBCol md="1">
                        {
                            eOrder.orderType === "Service" && lastChartNotes && lastChartNotes.id ?
                                <Button
                                    style={{ width: "100%", padding: '0 4px' }}
                                    aria-hidden="true"
                                    title={chartTip}
                                    onClick={() => {
                                        OrderService.downloadDoc(lastChartNotes.id)
                                            .then((res) => {
                                                // console.log(res)
                                                if (res) {
                                                    let file = new File([res], lastChartNotes.filename, null);
                                                    // console.log(file);
                                                    let url = window.URL.createObjectURL(file);
                                                    let temp = document.createElement("a");
                                                    temp.href = url;
                                                    temp.setAttribute("download", lastChartNotes.filename);
                                                    temp.click();
                                                    window.URL.revokeObjectURL(url);
                                                }
                                                else {
                                                    toast.warn("There was an issue downloading the file. It may not exist on the server yet.");
                                                }
                                            })
                                            .catch(err => {
                                                console.log(err);
                                                toast.error("There was an issue downloading the file.");
                                            });
                                    }}
                                >
                                    Chart Notes {GlobalFunctions.formatFlatDate(lastChartNotes.uploadedAt)}
                                </Button>
                                : ''
                        }
                    </MDBCol>
                    <MDBCol style={{display: 'flex', alignItems: 'center'}}>
                        <div>
                            <Route
                                render={({ history }) => (
                                    <h4
                                        // ! this general area is the place to put the thing
                                        aria-hidden="true"
                                        data-tip="Return to Patient"
                                        data-place={"bottom"}
                                        onClick={() => {
                                            history.push({
                                                pathname:
                                                    "/patientInformation/" +
                                                    patient.id,
                                                state: { patient: patient },
                                            });
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: 24,
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                                color: "white",
                                                margin: "auto",
                                                paddingTop: 5,
                                                textUnderlineOffset: '15%',
                                            }}
                                        >
                                            {patient.firstName +
                                                " " +
                                                patient.lastName +
                                                " " +
                                                (patient.suffix || "")}
                                            {renderDeliveryNoteIcon()}
                                        </p>
                                    </h4>
                                )}
                            />
                            {patient.brightreeExternalId ? (
                                <a
                                    href={`https://brightree.net/F1/02200/OrbitMedical/Patient/frmPatientSummary.aspx?PatientKey=${patient.brightreeExternalKey}&showAck=1`}
                                    target="_blank"
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: "#FFFFFF",
                                        textDecoration: 'underline',
                                    }}
                                >
                                    {"Brightree ID: " + patient.brightreeExternalId}
                                </a>
                            ) : null}
                        </div>

                    </MDBCol>
                    <MDBCol size="4">
                        {/*create quote*/}
                        {this.state.eOrder.orderType === "Service" &&
                            (this.state.eOrder.orderStatusReasonId === 61 ||
                                this.state.eOrder.orderStatusReasonId === 68 ||
                                this.state.eOrder.orderStatusReasonId === 72) ? (
                            <MDBBtn
                                outline
                                rounded
                                size={this.state.createQuoteSmall ? "md" : "sm"}
                                color={this.state.createQuoteSmall ? "amber" : "success"}
                                className="px-2 toolbarButton"
                                data-tip={this.state.createQuoteSmall ? "Continue Quote" : "Create Quote"}
                                data-place={"bottom"}
                                onClick={() => {
                                    Sentry.captureMessage(
                                        `Create Quote Modal button clicked`,
                                        {
                                            fingerprint: ['orderPageButtonActivity']
                                        }
                                    );
                                    this.toggleCreateQuoteModalPopup();
                                }}
                            >
                                <MDBIcon icon="comment-dollar" />
                            </MDBBtn>
                        ) : (
                            ""
                        )}
                        {/*endcreatequote*/}

                        {/*region view field notes*/}
                        {this.state.order.status === 3 ? (
                            <MDBBtn
                                outline
                                rounded
                                size="sm"
                                color="yellow"
                                className="px-2 toolbarButton"
                                data-tip={"View Field Notes"}
                                data-place={"bottom"}
                                onClick={() => {
                                    Sentry.captureMessage(
                                        `View Field Notes button clicked`,
                                        {
                                            fingerprint: ['orderPageButtonActivity']
                                        }
                                    );
                                    this.toggleFieldNotesModalPopup();
                                }}
                            >
                                <MDBIcon icon="envelope" />
                            </MDBBtn>
                        ) : (
                            ""
                        )}
                        {/*endregion*/}

                        {/*region add note*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="secondary"
                            className="px-2 toolbarButton"
                            data-tip={"Add Note"}
                            data-place={"bottom"}
                            onClick={() => {
                                this.setState({ isAutoVerification: false });
                                this.toggleNoteModalPopup();
                            }}
                        >
                            <MDBIcon icon="notes-medical" />
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region download notes*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="default"
                            className="px-2 toolbarButton"
                            data-tip={"Export Notes"}
                            data-place={"bottom"}
                            onClick={() => {
                                    Sentry.captureMessage(
                                        `Export Notes button clicked`,
                                        {
                                            fingerprint: ['orderPageButtonActivity']
                                        }
                                    );
                                this.downloadNotes()
                            }}
                        >
                            <MDBIcon icon="download" />
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region timelinemodal*/}
                        <MDBBtn
                            onClick={() => this.toggleTimelineModalPopup()}
                            data-tip={"Toggle Order Timeline"}
                            size="sm"
                            data-place={"bottom"}
                            outline
                            rounded
                            color="primary"
                            className="px-2 toolbarButton"
                        >
                            <MDBIcon icon="clock" />
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region request help*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="info"
                            className="px-2 toolbarButton"
                            disabled={!Security.hasPermission("order.requestPaperworkHelp")}
                            data-tip={"Request Paperwork Help"}
                            data-place={"bottom"}
                            onClick={() => this.getLiaisonMeetings()}
                        >
                            <MDBIcon icon="question" />
                        </MDBBtn>
                        {/*endregion*/}

                        {/*Region Home Assessment*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="elegant"
                            className="px-2 toolbarButton"
                            data-tip={"Home Assessment"}
                            data-place={"bottom"}
                            onClick={() => this.toggleHomeAssessmentModal()}
                        >
                            <MDBIcon icon="home" />
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region purchase*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color={"red"}
                            className="px-2 toolbarButton"
                            data-tip={dataTip}
                            data-place={"bottom"}
                            onClick={() => {
                                if (readyToPurchase) {
                                    Sentry.captureMessage(
                                        `Purchase Equipment button clicked`,
                                        {
                                            fingerprint: ['orderPageButtonActivity']
                                        }
                                    );
                                    this.setState({
                                        pqModal: !this.state.pqModal,
                                    });
                                }
                            }}
                        >
                            <MDBIcon icon="cart-plus" />
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region delivery manifest*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="amber"
                            className="px-2 toolbarButton"
                            data-tip={"Delivery Manifest"}
                            data-place={"bottom"}
                            onClick={this.renderDMWindow}
                        >
                            <MDBIcon icon="truck" />
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region approve*/}
                        <MDBBtn
                            onClick={() => this.canApproveOrder()}
                            data-tip={"Approve Order"}
                            data-place={"bottom"}
                            outline
                            rounded
                            size="sm"
                            color="orange"
                            className="px-2 toolbarButton"
                        >
                            <MDBIcon icon="check" />
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region add follow-up note*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="light grey"
                            className="px-2 toolbarButton"
                            data-tip={reason}
                            data-place={"bottom"}
                            onClick={() => this.toggleFollowupNoteModal()}
                        >
                            <MDBIcon icon="pen" />
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region save*/}
                        <MDBBtn
                            onClick={(e) => this.saveOrder(null)}
                            disabled={this.state.saveProcessStarted || this.state.disableOrderSave}
                            data-tip={"Save Order"}
                            data-place={"bottom"}
                            outline
                            rounded
                            size="sm"
                            color="success"
                            className="px-2 toolbarButton"
                        >
                            <MDBIcon icon="save" />
                        </MDBBtn>
                        {/*endregion*/}
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
        );
    }

    //   handleVendorChange = (e) => {
    //     if (e.value == null) {
    //       e = null;
    //     }
    //     this.setState({
    //       productVendor: e,
    //     });
    //     this.getVendorList();
    //   };

    //   handleProductChange = (e) => {
    //     // this.addProduct(e.value);

    //     this.setState({
    //       selectedProduct: null,
    //     });
    //   };

    renderProcessingModal() {
        return (
            <MDBModal
                centered
                contentClassName={"invisibleModal"}
                isOpen={this.state.saveProcessStarted}
                toggle={() => {
                    return;
                }}
            >
                <MDBRow style={{ justifyContent: "center" }}>
                    <MDBSpinner yellow />
                </MDBRow>

                <MDBRow style={{ justifyContent: "center" }}>
                    <h3>{"Processing..."}</h3>
                </MDBRow>
            </MDBModal>
        );
    }

    renderDeliveryNoteCard() {
        if (
            this.state.eOrder.deliveryNotes === null ||
            this.state.eOrder.deliveryNotes.length < 1
        ) {
            return;
        }
        return (
            <MDBCol md="5">
                <MDBCard className="deliveryNotes">
                    <div>
                        {"Delivery Notes: "}
                        {this.state.eOrder.deliveryNotes}
                    </div>
                </MDBCard>
            </MDBCol>
        );
    }

    //   Begin Create Quote Modal
    renderCreateQuoteModal() {
        const { productTypes, productSubTypes } = this.context;
        let st = this.state;
        if (!st.createQuoteOpen) {
            return;
        }
        return (

            <CreateQuoteModal

                small={this.state.createQuoteSmall}
                order={this.state.eOrder}
                products={this.state.orderProducts}
                productTypes={productTypes}
                productSubTypes={productSubTypes}
                saveOrder={(e) => this.saveOrder(null)}
                onToggle={() => {
                    this.setState({
                        createQuoteOpen: !this.state.createQuoteOpen,
                    });
                }}
                onSmall={() => {
                    this.setState({
                        createQuoteSmall: !this.state.createQuoteSmall,
                    });
                }}
            />

        );
    }
    // End Create Quote Modal

    renderFieldNotesModal() {
        return (
            <MDBModal
                centered
                isOpen={this.state.fieldNotesOpen}
                toggle={() =>
                    this.setState({
                        fieldNotesOpen: !this.state.fieldNotesOpen,
                    })
                }
            >
                <MDBModalHeader>
                    <h3>Field Notes</h3>
                </MDBModalHeader>
                <MDBModalBody>
                    <p>
                        {this.state.eOrder.orderActivityApprovals &&
                            this.state.eOrder.orderActivityApprovals.length > 0 &&
                            this.state.eOrder.orderActivityApprovals[0].approvals &&
                            this.state.eOrder.orderActivityApprovals[0].approvals
                                .fieldNotes
                            ? this.state.eOrder.orderActivityApprovals[0]
                                .approvals.fieldNotes
                            : "No Notes to display."}
                    </p>
                </MDBModalBody>
            </MDBModal>
        );
    }
    handleCollapseId(id) {
        this.setState({
            collapseID: id
        })
    }
    toggleProcessing() {
        this.setState({
            saveProcessStarted: true
        });
    }

    renderContent() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }

        if (this.state.orderNotFound) {
            return this.renderNotFound();
        }
        return (
            <div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                    style={{ marginTop: "75px" }}
                />
                <LiaisonModal
                    closeModal={this.closeHelpModal.bind(this)}
                    setExistingEmpty={this.setExistingEmpty.bind(this)}
                    existingMeeting={this.state.existingMeeting}
                    eOrder={this.state.eOrder}
                    resultingLiaisonKeys={this.state.resultingLiaisonKeys}
                    liaisonFullKeys={this.state.liaisonFullKeys}
                    helpModalOpen={this.state.helpModalOpen}
                />

                <CreateNoteModal
                    eOrder={this.state.eOrder}
                    allNotes={this.state.notes}
                    toggleProcessing={this.toggleProcessing.bind(this)}
                    toggleNoteModal={this.toggleNoteModalPopup.bind(this)}
                    addNoteUpdateOrderCloseModal={this.addNoteUpdateOrderCloseModal.bind(this)}
                    noteModalOpen={this.state.noteModalOpen}
                    noteAssignedTo={null}
                    text={this.state.noteText}
                    isAutoVerification={this.state.isAutoVerification}
                    onRadioChange={(e) => this.setState({ selectedVerificationNoteRadio: e.target.value === "true" })}
                    selectedVerificationNoteRadio={this.state.selectedVerificationNoteRadio}
                />
                {this.renderTimelineModal()}
                {this.renderPQModal()}
                {this.renderApprovalModal()}
                {this.renderBTIDModal()}
                {this.renderApprovalErrorsModal()}
                {this.renderViewToolbar()}
                {this.renderAbnModal()}
                {this.renderActionNoteModal()}
                {this.renderHomeAssessmentModal()}
                {this.renderProcessingModal()}
                {this.renderCreateQuoteModal()}
                {this.renderFieldNotesModal()}
                {this.renderProductDialog()}
                <div
                    className="container-fluid"
                    style={{ paddingRight: "25px" }}
                >
                    <Row style={{ paddingBottom: "1em" }}>
                        <Col size="3">
                            <Col size="12">
                                {/* Forcing rerender so proper status reasons are avalible after order save */}
                                {this.state.saveProcessStarted ? <></> : <TypeStatusContainer
                                    goto={this.state.goto}
                                    eOrder={this.state.eOrder}
                                    order={this.state.order}
                                    updateNotes={this.updateOrderNotes.bind(
                                        this
                                    )}
                                    collapseID={this.state.collapseID}
                                    handleCollapseId={this.handleCollapseId.bind(this)}
                                    saveOrder={(e) => this.saveOrder(null)}
                                    validateSave={this.validateSave.bind(this)}
                                    updateParent={this.updateParentObject.bind(
                                        this
                                    )}
                                    updateTableRows={this.updateTableRows.bind(
                                        this
                                    )}
                                />
                                }

                            </Col>
                        </Col>

                        <Col size="9">{this.renderTabSection()}</Col>
                    </Row>
                </div>
                {/*<ReactTooltip  className={"topMost"}/>*/}
            </div>
        );
    }
}
